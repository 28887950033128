import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_DecisionList_ActionsMenu_ApiEnum {
    'create_decision' = 'create_decision',
}

export enum Config_DecisionList_Modules_ApiEnum {
    'results' = 'results',
}

export enum Config_DecisionList_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_DecisionList_PageMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_DecisionList_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_DecisionList_ApiInterface
    extends ConfigDataInterface<
        Config_DecisionList_Modules_ApiEnum,
        Config_DecisionList_ActionsMenu_ApiEnum,
        Config_DecisionList_ActionsOther_ApiEnum,
        Config_DecisionList_PageMenu_ApiEnum,
        Config_DecisionList_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
