import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Club_PageMenu_ApiEnum } from './ClubDetailConfigApiInterface';
import { Config_ClubCompetition_PageSubMenu_ApiEnum } from './ClubCompetitionConfigApiInterface';

export enum Config_ClubCompetitionPlayerSpring_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_ClubCompetitionPlayerSpring_Modules_ApiEnum {
    'indications' = 'indications',
    'overview' = 'overview',
    'list_players' = 'list_players',
}

export enum Config_ClubCompetitionPlayerSpring_ActionsOther_ApiEnum {
    'upload_photo' = 'upload_photo',
}

export interface Config_ClubCompetitionPlayerSpring_ApiInterface
    extends ConfigDataInterface<
        Config_ClubCompetitionPlayerSpring_Modules_ApiEnum,
        Config_ClubCompetitionPlayerSpring_ActionsMenu_ApiEnum,
        Config_ClubCompetitionPlayerSpring_ActionsOther_ApiEnum,
        Config_Club_PageMenu_ApiEnum,
        Config_ClubCompetition_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
