import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Person_PageMenu_ApiEnum } from './PersonDetailConfigApiInterface';

export enum Config_PersonAccountRewardsDocuments_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_PersonAccountRewardsDocuments_Modules_ApiEnum {
    'indications' = 'indications',
    'overview' = 'overview',
    'results' = 'results',
}

export enum Config_PersonAccountRewardsDocuments_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_PersonAccountRewardsDocuments_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_PersonAccountRewardsDocuments_ApiInterface
    extends ConfigDataInterface<
        Config_PersonAccountRewardsDocuments_Modules_ApiEnum,
        Config_PersonAccountRewardsDocuments_ActionsMenu_ApiEnum,
        Config_PersonAccountRewardsDocuments_ActionsOther_ApiEnum,
        Config_Person_PageMenu_ApiEnum,
        Config_PersonAccountRewardsDocuments_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
