import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Competition_PageMenu_ApiEnum } from './CompetitionDetailConfigApiInterface';

export enum Config_CompetitionMatches_ActionsMenu_ApiEnum {
    'create_match' = 'create_match',
    'generate_competition_matches' = 'generate_competition_matches',
}

export enum Config_CompetitionMatches_Modules_ApiEnum {
    'indications' = 'indications',
    'overview' = 'overview',
    'matches' = 'matches',
    'warning' = 'warning',
    'generate_competition_matches' = 'generate_competition_matches',
    'print' = 'print',
}

export enum Config_CompetitionMatches_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_CompetitionMatches_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_CompetitionMatches_ApiInterface
    extends ConfigDataInterface<
        Config_CompetitionMatches_Modules_ApiEnum,
        Config_CompetitionMatches_ActionsMenu_ApiEnum,
        Config_CompetitionMatches_ActionsOther_ApiEnum,
        Config_Competition_PageMenu_ApiEnum,
        Config_CompetitionMatches_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
