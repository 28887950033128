import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_Applications_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_Applications_Modules_ApiEnum {
    'results' = 'results',
}

export enum Config_Applications_ActionsOther_ApiEnum {
    'create' = 'create',
}

export enum Config_Applications_PageMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_Applications_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_Applications_ApiInterface
    extends ConfigDataInterface<
        Config_Applications_Modules_ApiEnum,
        Config_Applications_ActionsMenu_ApiEnum,
        Config_Applications_ActionsOther_ApiEnum,
        Config_Applications_PageMenu_ApiEnum,
        Config_Applications_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
