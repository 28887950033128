import { NextApiRequestQuery } from 'next/dist/server/api-utils';
import {
    DisciplineEnum,
    EntityReferenceWithTypeApiInterface,
    PersonEntityReferenceApiInterface,
    TeamWithSummaryStatsApiInterface,
    OrganisationUnitListItemApiInterface,
    ClubBaseWithDisciplineApiInterface,
    EntityReferenceApiEnum,
    OrganisationUnitRoleApiEnum,
    ApplicationStateApiEnum,
    ApplicationTypeApiEnum,
    ProceedingEntityReferenceApiInterface,
} from '../ApiInterface';
import { ConfigModuleApiInterface } from '../config/ConfigApiInterface';
import { TransactionDocumentApiInterface } from './AccountTransactionInterface';
import { MatchApiInterface, ScoreApiInterface } from './MatchInterface';
import { SubsidyApiInterface } from './SubsidyInterface';

export type ConfigProceedingFieldsModule = Record<
    ProceedingPunishmentTypeEnum,
    ConfigProceedingFieldsDataModule
>;

export type ConfigProceedingFieldsDataModule = Record<
    ProceedingFieldsTypesEnum,
    ProceedingFieldsDataInterface
>;

export interface ProceedingFieldsDataInterface {
    type: ProceedingFieldsTypeEnum;
    values?: ProceedingFieldsValuesEnum[];
    filters?: NextApiRequestQuery;
}

export enum ProceedingFieldsTypeEnum {
    integer = 'integer',
    account_document_autocomplete = 'account_document_autocomplete',
    match_autocomplete = 'match_autocomplete',
    select = 'select',
    multiselect = 'multiselect',
    transaction_template_autocomplete = 'transaction_template_autocomplete',
    date = 'date',
    datetime = 'datetime',
    competition_team_autocomplete = 'competition_team_autocomplete',
    subsidy_autocomplete = 'subsidy_autocomplete',
    competition_autocomplete = 'competition_autocomplete',
}

export enum ProceedingFieldsValuesEnum {
    both = 'both',
    guest_team_winner = 'guest_team_winner',
    home_team_winner = 'home_team_winner',
    leave_as_is = 'leave_as_is',
    player = 'player',
    referee = 'referee',
    delegate = 'delegate',
    match_person = 'match_person',
}

export enum ProceedingFieldsTypesEnum {
    break = 'break',
    competition = 'competition',
    competition_id = 'competition_id',
    transaction_template_id = 'transaction_template_id',
    competition_team_id = 'competition_team_id',
    competition_team = 'competition_team',
    account_document_id = 'account_document_id',
    account_document = 'account_document',
    match_id = 'match_id',
    match = 'match',
    subsidy_ids = 'subsidy_ids',
    subsidies = 'subsidies',
    match_ids = 'match_ids',
    matches = 'matches',
    amount = 'amount',
    from_date = 'from_date',
    competition_matches_count = 'competition_matches_count',
    days_count = 'days_count',
    competition_days_count = 'competition_days_count',
    hours_count = 'hours_count',
    contumacy_type = 'contumacy_type',
    points_count = 'points_count',
    person_roles = 'person_roles',
    competition_category_type = 'competition_category_type',
    competition_level_type = 'competition_level_type',
    competition_group_type = 'competition_group_type',
    competition_code = 'competition_code',
}

export interface ProceedingPunishmentApiInterface {
    id: number;
    type: ProceedingPunishmentTypeEnum;
    settings: ProceedingPunishmentSettingsApiType;
    state: ProceedingPunishmentStateEnum;
    applied_at?: string;
    created_at: string;
    updated_at: string;
    actions?: ProceedingPunishmentActionsApiInterface;
}

export interface ProceedingPunishmentActionsApiInterface {
    cancel_punishment: ConfigModuleApiInterface;
    delete_punishment: ConfigModuleApiInterface;
    edit_punishment: ConfigModuleApiInterface;
}

export enum ProceedingPunishmentStateEnum {
    draft = 'draft',
    active = 'active',
    pending = 'pending',
    canceled_in_appeal = 'canceled_in_appeal',
    canceled_in_application = 'canceled_in_application',
    finished = 'finished',
}

export interface ProceedingApplicationListApiInterface {
    id: number;
    type: ApplicationTypeApiEnum;
    state: ApplicationStateApiEnum;
    applicant: EntityReferenceWithTypeApiInterface;
    fields: Record<string, ProceedingApplicationFieldsApiInterface>;
    created_at: string;
    updated_at: string;
}

export interface ProceedingApplicationFieldsApiInterface {
    type: string;
    value: string;
}

export type ProceedingPunishmentSettingsApiType =
    Partial<ProceedingPunishmentSettingsApiInterface>;

export interface ProceedingPunishmentSettingsApiInterface
    extends PPSettingsTransactionTemplateInterface,
        PPSettingsFromDateInterface,
        PPSettingsCompetitionMatchesCountWithDaysCountInterface,
        PPSettingsDaysCountInterface,
        PPSettingsHoursCountInterface,
        PPSettingsSubsidiesInterface,
        PPSettingsAccountDocumentInterface,
        PPSettingsMatchesInterface,
        PPSettingsCompetitionMatchesCountInterface,
        PPSettingsMatchWithContumacyTypeInterface {}

export interface PPSettingsTransactionTemplateInterface {
    transaction_template_id: number;
    amount: number;
}

export interface PPSettingsFromDateInterface {
    from_date: string;
}

export interface PPSettingsCompetitionMatchesCountWithDaysCountInterface
    extends PPSettingsDaysCountInterface {
    competition_matches_count: number;
}

export interface PPSettingsDaysCountInterface {
    days_count: number;
}

export interface PPSettingsHoursCountInterface {
    hours_count: number;
}

export interface PPSettingsSubsidiesInterface {
    subsidy_ids?: number[];
    subsidies?: SubsidyApiInterface[];
}

export interface PPSettingsAccountDocumentInterface {
    account_document_id: number;
    account_document: TransactionDocumentApiInterface;
}

export interface PPSettingsMatchesInterface {
    match_ids?: number[];
    matches?: MatchApiInterface[];
}

export interface PPSettingsCompetitionMatchesCountInterface {
    competition_matches_count: number;
}

export interface PPSettingsMatchWithContumacyTypeInterface {
    match_id: number;
    contumacy_type: PPSettingsContumacyTypeEnum;
}

export enum PPSettingsContumacyTypeEnum {
    home_team_winner = 'home_team_winner',
    guest_team_winner = 'guest_team_winner',
    both = 'both',
    leave_as_is = 'leave_as_is',
}

export interface PPSettingsPointsCountInterface {
    points_count: number;
}

export enum ProceedingPunishmentTypeEnum {
    reprimand = 'reprimand',
    fine = 'fine',
    exclusion = 'exclusion',
    ban_for_playing = 'ban_for_playing',
    ban_for_functioning = 'ban_for_functioning',
    ban_for_other_activities = 'ban_for_other_activities',
    ban_stadium_entry = 'ban_stadium_entry',
    ban_all_activities = 'ban_all_activities',
    football_beneficial_activity = 'football_beneficial_activity',
    subsidy_return = 'subsidy_return',
    ban_for_subsidies = 'ban_for_subsidies',
    ban_for_transfers_or_hosting = 'ban_for_transfers_or_hosting',
    match_without_spectators = 'match_without_spectators',
    stadium_close = 'stadium_close',
    match_contumacy = 'match_contumacy',
    competition_points_removal = 'competition_points_removal',
    competition_team_exclusion = 'competition_team_exclusion',
    ban_all_functions = 'ban_all_functions',
}

export interface ProceedingDetailApiInterface {
    proceeding: ProceedingApiInterface;
}

export interface ProceedingStopApiInterface {
    reason: string;
}

export interface ProceedingUpdateApiInterface {
    decision?: string;
    edification?: string;
}

export interface ProceedingPostponeApiInterface {
    postpone_to: string;
}

export interface ProceedingAppealEndApiInterface {
    appeal_result_type: ProtestApprovedState;
}

export enum ProceedingPassEntityTypeApiEnum {
    uefa = 'uefa',
    fifa = 'fifa',
    organisation_unit = 'organisation_unit',
}

export enum ProtestApprovedState {
    satisfied = 'satisfied',
    unsatisfied = 'unsatisfied',
    partially_satisfied = 'partially_satisfied',
}

export interface ProceedingDecideApiInterface {
    decision: string;
    edification: string;
    protest_result_type?: ProtestApprovedState;
}

export interface ProceedingPassApiInterface {
    pass_to_entity_type: ProceedingPassEntityTypeApiEnum;
    pass_to_organisation_unit_id?: number;
    pass_to_organisation_unit_role?: OrganisationUnitRoleApiEnum;
    note: string;
}

export interface ProceedingPunishmentCreateApiInterface {
    type: ProceedingPunishmentTypeEnum;
    settings: ProceedingPunishmentSettingsApiInterface;
}

export interface ProceedingCreateApiInterface {
    discipline: DisciplineEnum;
    type: ProceedingTypeApiEnum;
    conducted_with_type: EntityReferenceApiEnum;
    conducted_with_id: number;
    note: string;
    match_id: number;
    organisation_unit_id: number;
}

export interface ProceedingEntityReferenceWithNoteApiInterface
    extends ProceedingEntityReferenceApiInterface {
    passed_to_note: string;
}

export interface ProceedingApiInterface {
    id: number;
    type: ProceedingTypeApiEnum;
    discipline: DisciplineEnum;
    state: ProceedingStateApiEnum;
    identifier: string;
    note: string;
    conducted_with: EntityReferenceWithTypeApiInterface;
    author_person: PersonEntityReferenceApiInterface;
    match: ProceedingMatchApiInterface;
    organisation_unit: OrganisationUnitListItemApiInterface;
    competition_year: number;
    responsible_club: ClubBaseWithDisciplineApiInterface;
    postponed_to: string;
    passed_from: ProceedingEntityReferenceWithNoteApiInterface;
    passed_to_entity_type: ProceedingPassEntityTypeApiEnum;
    passed_to_organisation_unit: OrganisationUnitListItemApiInterface;
    passed_to_organisation_unit_role: OrganisationUnitRoleApiEnum;
    passed_to_note: string;
    decision: string;
    edification: string;
    stop_reason: string;
    decided_at: string;
    created_at: string;
    updated_at: string;
}

export interface ProceedingListApiInterface {
    id: number;
    identifier: string;
    type: ProceedingTypeApiEnum;
    discipline: DisciplineEnum;
    state: ProceedingStateApiEnum;
    conducted_with: EntityReferenceWithTypeApiInterface;
    author_person: PersonEntityReferenceApiInterface;
    match: ProceedingMatchApiInterface;
    organisation_unit: OrganisationUnitListItemApiInterface;
    created_at: string;
    updated_at: string;
    actions?: ProceedingListActionsApiInterface;
}

export interface ProceedingListActionsApiInterface {
    create_proceeding_justification_request: ConfigModuleApiInterface;
    create_proceeding_punishment_cancel_action: ConfigModuleApiInterface;
    'show_proceeding_application_detail:proceeding_justification_request': ConfigModuleApiInterface;
}

export enum ProceedingTypeApiEnum {
    stk = 'stk',
    dk = 'dk',
}

export enum ProceedingStateApiEnum {
    draft = 'draft',
    started = 'started',
    stopped = 'stopped',
    decided = 'decided',
    postponed = 'postponed',
    passed = 'passed',
    appeal = 'appeal',
    decided_appeal = 'decided_appeal',
}

export interface ProceedingMatchApiInterface {
    id: number;
    identifier: string;
    playing_at: string;
    round: number;
    score: ScoreApiInterface;
    home_team: TeamWithSummaryStatsApiInterface;
    guest_team: TeamWithSummaryStatsApiInterface;
    competition: ProceedingCompetitionTeamApiInterface;
}

export interface ProceedingCompetitionTeamApiInterface {
    id: number;
    name: string;
    identifier: string;
    discipline: DisciplineEnum;
}
