import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Club_PageMenu_ApiEnum } from './ClubDetailConfigApiInterface';

export enum Config_ClubTransfers_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_ClubTransfers_Modules_ApiEnum {
    'overview' = 'overview',
    'indications' = 'indications',
    'transfers' = 'transfers',
}

export enum Config_ClubTransfers_ActionsOther_ApiEnum {
    'upload_photo' = 'upload_photo',
}

export enum Config_ClubTransfers_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_ClubTransfers_ApiInterface
    extends ConfigDataInterface<
        Config_ClubTransfers_Modules_ApiEnum,
        Config_ClubTransfers_ActionsMenu_ApiEnum,
        Config_ClubTransfers_ActionsOther_ApiEnum,
        Config_Club_PageMenu_ApiEnum,
        Config_ClubTransfers_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
