import {
    ClubBaseWithDisciplineApiInterface,
    CompetitionCategoryTypeApiEnum,
    CompetitionGroupTypeApiEnum,
    CompetitionTeamStateApiEnum,
    PitchApiInterface,
    SeasonApiEnum,
    TeamSummaryStatsDirectionEnum,
} from '../ApiInterface';
import { ConfigModuleApiInterface } from '../config/ConfigApiInterface';
import { PersonBasicDataApiInterface } from './PersonInterface';

export interface CompetitionLightInterface {
    id: number;
    name: string;
    identifier: string;
    year: number;
    category_type: CompetitionCategoryTypeApiEnum;
    level_type: number;
    group_type: CompetitionGroupTypeApiEnum;
    current_round: number;
}

export interface CompetitionTeamApiInterface {
    id: number;
    name: string;
    small_name: string;
    number: number;
    default_points: number;
    default_score: {
        scored: number;
        got: number;
    };
    players_count: number;
    state: CompetitionTeamStateApiEnum;
    club: ClubBaseWithDisciplineApiInterface;
    associated_club: ClubBaseWithDisciplineApiInterface;
    pitch: PitchApiInterface;
    summary_stats_direction: TeamSummaryStatsDirectionEnum;
    competition: CompetitionLightInterface;
    list_players: CompetitionTeamListPlayerApiType;
    actions?: Record<CompetitionTeamActionApiEnum, ConfigModuleApiInterface>;
    competition_start_fee_transaction_id: number | null;
}

export type CompetitionTeamListPlayerApiType = Record<
    SeasonApiEnum,
    CompetitionTeamListPlayerSeasonApiInterface
>;

export interface CompetitionTeamListPlayerSeasonApiInterface {
    approved_by_person: PersonBasicDataApiInterface;
    approved_at: string;
}

export interface CompetitionCreateTeamListPlayer {
    person_ids: number[];
    season: SeasonApiEnum;
}

export interface CompetitionDeleteTeamListPlayer {
    person_id: number;
    season: SeasonApiEnum;
}

export enum CompetitionTeamActionApiEnum {
    'associate_club_to_competition_team' = 'associate_club_to_competition_team',
    'deassociate_club_from_competition_team' = 'deassociate_club_from_competition_team',
    'create_competition_start_fee_transaction' = 'create_competition_start_fee_transaction',
}
