import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_OrganisationUnitDetail_PageMenu_ApiEnum } from './OrganisationUnitDetailConfigApiInterface';

export enum Config_OrganisationUnitAccountGrantDocument_ActionsMenu_ApiEnum {
    'print_document' = 'print_document',
    'mark_document_as_final' = 'mark_document_as_final',
}

export enum Config_OrganisationUnitAccountGrantDocument_Modules_ApiEnum {
    'overview' = 'overview',
    'results' = 'results',
}

export enum Config_OrganisationUnitAccountGrantDocument_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_OrganisationUnitAccountGrantDocument_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_OrganisationUnitAccountGrantDocument_ApiInterface
    extends ConfigDataInterface<
        Config_OrganisationUnitAccountGrantDocument_Modules_ApiEnum,
        Config_OrganisationUnitAccountGrantDocument_ActionsMenu_ApiEnum,
        Config_OrganisationUnitAccountGrantDocument_ActionsOther_ApiEnum,
        Config_OrganisationUnitDetail_PageMenu_ApiEnum,
        Config_OrganisationUnitAccountGrantDocument_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
