import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_MyProceedings_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_MyProceedings_Modules_ApiEnum {
    'results' = 'results',
}

export enum Config_MyProceedings_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_MyProceedings_PageMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_MyProceedings_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_MyProceedings_ApiInterface
    extends ConfigDataInterface<
        Config_MyProceedings_Modules_ApiEnum,
        Config_MyProceedings_ActionsMenu_ApiEnum,
        Config_MyProceedings_ActionsOther_ApiEnum,
        Config_MyProceedings_PageMenu_ApiEnum,
        Config_MyProceedings_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
