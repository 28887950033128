import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_OrganisationUnitDetail_PageMenu_ApiEnum } from './OrganisationUnitDetailConfigApiInterface';

export enum Config_OrganisationUnitsSearch_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_OrganisationUnitsSearch_Modules_ApiEnum {
    'results' = 'results',
}

export enum Config_OrganisationUnitsSearch_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_OrganisationUnitsSearch_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_OrganisationUnitsSearch_ApiInterface
    extends ConfigDataInterface<
        Config_OrganisationUnitsSearch_Modules_ApiEnum,
        Config_OrganisationUnitsSearch_ActionsMenu_ApiEnum,
        Config_OrganisationUnitsSearch_ActionsOther_ApiEnum,
        Config_OrganisationUnitDetail_PageMenu_ApiEnum,
        Config_OrganisationUnitsSearch_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
