interface LegalPersonCreateAddress {
    street: string;
    street_number: string;
    city: string;
    postal_code: string;
    country_code: string;
}

export enum DeliveryType {
    no_delivery = 'no_delivery',
    headquarters_address = 'headquarters_address',
    delivery_address = 'delivery_address',
}

export interface LegalPersonCreateApiInterface {
    name: string;
    internal_id: string;
    headquarters: LegalPersonCreateAddress;
    primary_czech_data_box: string;
    additional_emails: string[];
    additional_phones: string[];
    additional_czech_data_boxes: string[];
    bank_account: {
        prefix?: string;
        number: string;
        bank_code: string;
    };
    invoice_information: {
        czech_id_number: string;
        czech_tax_id_number?: string;
        double_entry_bookkeeping: boolean;
        vat_payer: boolean;
    };
    delivery_addresses_type: DeliveryType;
    delivery_addresses?: LegalPersonCreateDeliveryAddresses;
}

interface LegalPersonCreateDeliveryAddresses extends LegalPersonCreateAddress {
    email: string;
    recipient: string;
}
