import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Match_PageMenu_ApiEnum } from './MatchDetailConfigApiInterface';

export enum Config_MatchHomeVisitor_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_MatchHomeVisitor_Modules_ApiEnum {
    'indications' = 'indications',
    'overview' = 'overview',
    'players' = 'players',
    'persons' = 'persons',
    'warning' = 'warning',
}

export enum Config_MatchHomeVisitor_ActionsOther_ApiEnum {
    'export' = 'export',
    'manage_goals' = 'manage_goals',
    'manage_players' = 'manage_players',
    'manage_persons' = 'manage_persons',
    'manage_punishments' = 'manage_punishments',
    'manage_rotations' = 'manage_rotations',
    'fill_players_from_previous_match' = 'fill_players_from_previous_match',
}

export enum Config_MatchHomeVisitor_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_MatchHomeVisitor_ApiInterface
    extends ConfigDataInterface<
        Config_MatchHomeVisitor_Modules_ApiEnum,
        Config_MatchHomeVisitor_ActionsMenu_ApiEnum,
        Config_MatchHomeVisitor_ActionsOther_ApiEnum,
        Config_Match_PageMenu_ApiEnum,
        Config_MatchHomeVisitor_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
