import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_InternalUsers_ActionsMenu_ApiEnum {
    'create_user' = 'create_user',
    'create_user_account' = 'create_user_account',
}

export enum Config_InternalUsers_Modules_ApiEnum {
    'results' = 'results',
}

export enum Config_InternalUsers_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_InternalUsers_PageMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_InternalUsers_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_InternalUsers_ApiInterface
    extends ConfigDataInterface<
        Config_InternalUsers_Modules_ApiEnum,
        Config_InternalUsers_ActionsMenu_ApiEnum,
        Config_InternalUsers_ActionsOther_ApiEnum,
        Config_InternalUsers_PageMenu_ApiEnum,
        Config_InternalUsers_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
