import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Club_PageMenu_ApiEnum } from './ClubDetailConfigApiInterface';

export enum Config_ClubPitches_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_ClubPitches_Modules_ApiEnum {
    'overview' = 'overview',
    'indications' = 'indications',
    'pitches' = 'pitches',
}

export enum Config_ClubPithces_ActionsOther_ApiEnum {
    'upload_photo' = 'upload_photo',
}

export enum Config_ClubPithces_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_ClubPitches_ApiInterface
    extends ConfigDataInterface<
        Config_ClubPitches_Modules_ApiEnum,
        Config_ClubPitches_ActionsMenu_ApiEnum,
        Config_ClubPithces_ActionsOther_ApiEnum,
        Config_Club_PageMenu_ApiEnum,
        Config_ClubPithces_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
