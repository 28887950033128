import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Person_PageMenu_ApiEnum } from './PersonDetailConfigApiInterface';

export enum Config_PlayerContract_ActionsMenu_ApiEnum {
    'edit_contract' = 'edit_contract',
    'archive_contract' = 'archive_contract',
    'deactivate_contract' = 'deactivate_contract',
}

export enum Config_PlayerContract_Modules_ApiEnum {
    'indications' = 'indications',
    'overview' = 'overview',
    'basic' = 'basic',
    'data' = 'data',
}

export enum Config_PlayerContract_ActionsOther_ApiEnum {
    'upload_photo' = 'upload_photo',
}

export enum Config_PlayerContract_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_PlayerContract_ApiInterface
    extends ConfigDataInterface<
        Config_PlayerContract_Modules_ApiEnum,
        Config_PlayerContract_ActionsMenu_ApiEnum,
        Config_PlayerContract_ActionsOther_ApiEnum,
        Config_Person_PageMenu_ApiEnum,
        Config_PlayerContract_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
