import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_AccountTransactions_ActionsMenu_ApiEnum {
    'create_accounts_documents' = 'create_accounts_documents',
    'add_transaction' = 'add_transaction',
}

export enum Config_AccountTransactions_Modules_ApiEnum {
    'results' = 'results',
}

export enum Config_AccountTransactions_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_AccountTransactions_PageMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_AccountTransactions_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_AccountTransactions_ApiInterface
    extends ConfigDataInterface<
        Config_AccountTransactions_Modules_ApiEnum,
        Config_AccountTransactions_ActionsMenu_ApiEnum,
        Config_AccountTransactions_ActionsOther_ApiEnum,
        Config_AccountTransactions_PageMenu_ApiEnum,
        Config_AccountTransactions_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
