import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_ProceedingsCreate_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_ProceedingsCreate_Modules_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_ProceedingsCreate_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_ProceedingsCreate_PageMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_ProceedingsCreate_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_ProceedingsCreate_ApiInterface
    extends ConfigDataInterface<
        Config_ProceedingsCreate_Modules_ApiEnum,
        Config_ProceedingsCreate_ActionsMenu_ApiEnum,
        Config_ProceedingsCreate_ActionsOther_ApiEnum,
        Config_ProceedingsCreate_PageMenu_ApiEnum,
        Config_ProceedingsCreate_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
