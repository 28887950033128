import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Club_PageMenu_ApiEnum } from './ClubDetailConfigApiInterface';

export enum Config_ClubAccountSubsidies_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_ClubAccountSubsidies_Modules_ApiEnum {
    'indications' = 'indications',
    'overview' = 'overview',
}

export enum Config_ClubAccountSubsidies_ActionsOther_ApiEnum {
    'upload_photo' = 'upload_photo',
}

export enum Config_ClubAccountSubsidies_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_ClubAccountSubsidies_ApiInterface
    extends ConfigDataInterface<
        Config_ClubAccountSubsidies_Modules_ApiEnum,
        Config_ClubAccountSubsidies_ActionsMenu_ApiEnum,
        Config_ClubAccountSubsidies_ActionsOther_ApiEnum,
        Config_Club_PageMenu_ApiEnum,
        Config_ClubAccountSubsidies_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
