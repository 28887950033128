import {
    ClubPassportApiInterface,
    DisciplineEnum,
    LevelTypeEnum,
    MemberApiInterface,
    OrganisationUnitApiInterface,
    PersonDataApiInterface,
    PersonEntityReferenceApiInterface,
    PhotoLogoApiInterface,
    PitchApiInterface,
    RegistrationCauseEnum,
    RegistrationTypeEnum,
    TransferDirectionEnum,
    TransferTypeEnum,
} from '../ApiInterface';
import { ConfigModuleApiInterface } from '../config/ConfigApiInterface';

export interface ClubPersonsListApiInterface {
    member: MemberApiInterface;
    person: PersonDataApiInterface;
    club_person: ClubPersonApiInterface;
}

export interface ClubPersonApiInterface {
    club_roles: ClubPersonRolesApiEnum[];
}

export interface ClubPersonCreateApiInterface {
    person_id: number;
    roles: ClubPersonRolesApiEnum[];
}

export interface ClubPersonAdminCreateApiInterface {
    person_id: number;
    role: ClubPersonRolesApiEnum;
}

export enum ClubPersonRolesApiEnum {
    ADMIN = 'admin',
    CLUB_MEMBER = 'club_member',
    COACH = 'coach',
    FUNCTIONARY = 'functionary',
    ORGANIZER = 'organizer',
    OTHER = 'other',
    PHYSICIAN = 'physician',
    RECORD_ADMIN = 'record_admin',
    REGISTRATIONS_ADMIN = 'registrations_admin',
    AUTHORIZED_ADMIN = 'authorized_admin',
}

export interface ClubMemberWithoutMembershipApiInterface {
    member: MemberApiInterface;
    club_roles: ClubPersonRolesApiEnum[];
    amount: number;
}

export interface ClubCreateApiInterface {
    name: string;
    legal_person_id: number;
    organisation_unit_id: number;
    admin_person_id: number;
    discipline: DisciplineEnum;
    web?: string;
    transfers_disabled: boolean;
    professional_licence: boolean;
    professional_licence_from: string;
    professional_licence_to: string;
}

export interface ClubPlayerTransferRegistrationInterface {
    id: number;
    club: ClubPassportApiInterface;
    active: boolean;
    discipline: DisciplineEnum;
    registration_cause: RegistrationCauseEnum;
    registration_type: RegistrationTypeEnum;
    transfer_type: TransferTypeEnum;
    transfer_direction: TransferDirectionEnum;
    level_type: LevelTypeEnum;
    date_from: string;
    date_to: string;
    is_temporary: boolean;
    person: PersonEntityReferenceApiInterface;
}

export enum ClubPlayerTransferDeclarationTypeEnum {
    'extension' = 'extension',
    'transfer' = 'transfer',
    'hosting' = 'hosting',
}

export enum ClubPlayerTransferDeclarationStateEnum {
    'draft' = 'draft',
    'approved' = 'approved',
}

export interface ClubPlayerTransferDeclarationInterface {
    id: number;
    type: ClubPlayerTransferDeclarationTypeEnum;
    state: ClubPlayerTransferDeclarationStateEnum;
    actions: ClubPlayerTransferDeclarationInterfaceActions;
}

export type ClubPlayerTransferDeclarationInterfaceActions = Record<
    ClubPlayerTransferDeclarationInterfaceActionsEnum,
    ConfigModuleApiInterface
>;

export enum ClubPlayerTransferDeclarationInterfaceActionsEnum {
    fill_transfer_declaration = 'fill_transfer_declaration',
    declaration_approve = 'declaration_approve',
    transfer_declaration_detail = 'transfer_declaration_detail',
}

export interface ClubPlayerTransferInterface {
    registration: ClubPlayerTransferRegistrationInterface;
    declarations: ClubPlayerTransferDeclarationInterface[];
}

export interface ClubListApiInterface {
    id: number;
    internal_id: string;
    name: string;
    discipline: DisciplineEnum;
    pitch: PitchApiInterface;
    logo: PhotoLogoApiInterface;
    organisation_unit: OrganisationUnitApiInterface;
}
