import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Club_PageMenu_ApiEnum } from './ClubDetailConfigApiInterface';
import { Config_ClubCompetition_PageSubMenu_ApiEnum } from './ClubCompetitionConfigApiInterface';

export enum Config_ClubCompetitionPlayerAutumn_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_ClubCompetitionPlayerAutumn_Modules_ApiEnum {
    'indications' = 'indications',
    'overview' = 'overview',
    'list_players' = 'list_players',
}

export enum Config_ClubCompetitionPlayerAutumn_ActionsOther_ApiEnum {
    'upload_photo' = 'upload_photo',
}

export interface Config_ClubCompetitionPlayerAutumn_ApiInterface
    extends ConfigDataInterface<
        Config_ClubCompetitionPlayerAutumn_Modules_ApiEnum,
        Config_ClubCompetitionPlayerAutumn_ActionsMenu_ApiEnum,
        Config_ClubCompetitionPlayerAutumn_ActionsOther_ApiEnum,
        Config_Club_PageMenu_ApiEnum,
        Config_ClubCompetition_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
