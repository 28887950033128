import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_MatchDetail_ActionsMenu_ApiEnum {
    'create_match_change_request' = 'create_match_change_request',
    'create_match_protest' = 'create_match_protest',
    'export_protest' = 'export_protest',
    'export_protest:home' = 'export_protest:home',
    'export_protest:guest' = 'export_protest:guest',
}

export enum Config_MatchDetail_Modules_ApiEnum {
    'indications' = 'indications',
    'overview' = 'overview',
    'fields' = 'fields',
    'schedule' = 'schedule',
    'referees' = 'referees',
    'warning' = 'warning',
}

export enum Config_MatchDetail_ActionsOther_ApiEnum {
    'fill_assignments_home' = 'fill_assignments_home',
    'fill_assignments_guest' = 'fill_assignments_guest',
    'match_did_not_start' = 'match_did_not_start',
    'end_match' = 'end_match',
    'cancel_match' = 'cancel_match',
    'confirm_home' = 'confirm_home',
    'confirm_guest' = 'confirm_guest',
    'close_record' = 'close_record',
    'update_match_pitch' = 'update_match_pitch',
}

export enum Config_Match_PageMenu_ApiEnum {
    'match-detail' = 'match-detail',
    'match-sum' = 'match-sum',
    'match-rewards' = 'match-rewards',
    'match-delegate-report' = 'match-delegate-report',
    'match-referee-reports' = 'match-referee-reports',
    'match-guest-assignments' = 'match-guest-assignments',
    'match-home-assignments' = 'match-home-assignments',
    'match-audit-log' = 'match-audit-log',
}

export enum Config_Match_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_MatchDetail_ApiInterface
    extends ConfigDataInterface<
        Config_MatchDetail_Modules_ApiEnum,
        Config_MatchDetail_ActionsMenu_ApiEnum,
        Config_MatchDetail_ActionsOther_ApiEnum,
        Config_Match_PageMenu_ApiEnum,
        Config_Match_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
