import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_ProceedingsDetail_ActionsMenu_ApiEnum {
    'start_proceeding' = 'start_proceeding',
    'stop_proceeding' = 'stop_proceeding',
    'decide_proceeding' = 'decide_proceeding',
    'postpone_proceeding' = 'postpone_proceeding',
    'pass_proceeding' = 'pass_proceeding',
    'start_proceeding_appeal' = 'start_proceeding_appeal',
    'end_proceeding_appeal' = 'end_proceeding_appeal',
}

export enum Config_ProceedingsDetail_Modules_ApiEnum {
    'conducted_with' = 'conducted_with',
    'decision' = 'decision',
    'edification' = 'edification',
}

export enum Config_ProceedingsDetail_ActionsOther_ApiEnum {
    'create_punishment' = 'create_punishment',
}

export enum Config_ProceedingsDetail_PageMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_ProceedingsDetail_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_ProceedingsDetail_ApiInterface
    extends ConfigDataInterface<
        Config_ProceedingsDetail_Modules_ApiEnum,
        Config_ProceedingsDetail_ActionsMenu_ApiEnum,
        Config_ProceedingsDetail_ActionsOther_ApiEnum,
        Config_ProceedingsDetail_PageMenu_ApiEnum,
        Config_ProceedingsDetail_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
