import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_DecisionDetail_ActionsMenu_ApiEnum {
    'decision_delete' = 'decision_delete',
    'decision_edit' = 'decision_edit',
}

export enum Config_DecisionDetail_Modules_ApiEnum {
    'form' = 'form',
}

export enum Config_DecisionDetail_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_DecisionDetail_PageMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_DecisionDetail_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_DecisionDetail_ApiInterface
    extends ConfigDataInterface<
        Config_DecisionDetail_Modules_ApiEnum,
        Config_DecisionDetail_ActionsMenu_ApiEnum,
        Config_DecisionDetail_ActionsOther_ApiEnum,
        Config_DecisionDetail_PageMenu_ApiEnum,
        Config_DecisionDetail_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
