import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_Subsidy_ActionsMenu_ApiEnum {
    'update_subsidy' = 'update_subsidy',
    'delete_subsidy' = 'delete_subsidy',
}

export enum Config_Subsidy_Modules_ApiEnum {
    'indications' = 'indications',
    'legal_person' = 'legal_person',
    'templates' = 'templates',
}

export enum Config_Subsidy_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_Subsidy_PageMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_Subsidy_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_SubsidyDetail_ApiInterface
    extends ConfigDataInterface<
        Config_Subsidy_Modules_ApiEnum,
        Config_Subsidy_ActionsMenu_ApiEnum,
        Config_Subsidy_ActionsOther_ApiEnum,
        Config_Subsidy_PageMenu_ApiEnum,
        Config_Subsidy_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
