import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_AuditLog_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_AuditLog_Modules_ApiEnum {
    'results' = 'results',
}

export enum Config_AuditLog_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_AuditLog_PageMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_AuditLog_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_AuditLog_ApiInterface
    extends ConfigDataInterface<
        Config_AuditLog_Modules_ApiEnum,
        Config_AuditLog_ActionsMenu_ApiEnum,
        Config_AuditLog_ActionsOther_ApiEnum,
        Config_AuditLog_PageMenu_ApiEnum,
        Config_AuditLog_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
