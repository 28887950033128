import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_PersonDetail_ActionsMenu_ApiEnum {
    'archive' = 'archive',
}

export enum Config_PersonDetail_Modules_ApiEnum {
    'indications' = 'indications',
    'overview' = 'overview',
    'contacts' = 'contacts',
    'memberships' = 'memberships',
    'matches' = 'matches',
    'statistics' = 'statistics',
    'penalization' = 'penalization',
    'system' = 'system',
}

export enum Config_PersonDetail_ActionsOther_ApiEnum {
    'upload_photo' = 'upload_photo',
}

export enum Config_Person_PageMenu_ApiEnum {
    'person-detail' = 'person-detail',
    'person-fields' = 'person-fields',
    'person-billing-fields' = 'person-billing-fields',
    'person-absences' = 'person-absences',
    'member-invoice-account' = 'member-invoice-account',
    'member-penalization' = 'member-penalization',
    'member-requests' = 'member-requests',
    'player-detail' = 'player-detail',
    'player-contracts' = 'player-contracts',
    'player-matches' = 'player-matches',
    'referee-detail' = 'referee-detail',
    'referee-licenses' = 'referee-licenses',
    'referee-matches' = 'referee-matches',
    'coach-detail' = 'coach-detail',
    'coach-licenses' = 'coach-licenses',
    'coach-matches' = 'coach-matches',
    'delegate-detail' = 'delegate-detail',
    'delegate-licenses' = 'delegate-licenses',
    'delegate-matches' = 'delegate-matches',
    'user-detail' = 'user-detail',
    'member-applications' = 'member-applications',
    'person-account-transactions' = 'person-account-transactions',
    'person-account-rewards-documents' = 'person-account-rewards-documents',
    'person-account-issued-documents' = 'person-account-issued-documents',
    'person-agent-detail' = 'person-agent-detail',
    'person-agent-contracts' = 'person-agent-contracts',
    'person-agent-insurances' = 'person-agent-insurances',
    'person-audit-log' = 'person-audit-log',
}

export enum Config_Person_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_PersonDetail_ApiInterface
    extends ConfigDataInterface<
        Config_PersonDetail_Modules_ApiEnum,
        Config_PersonDetail_ActionsMenu_ApiEnum,
        Config_PersonDetail_ActionsOther_ApiEnum,
        Config_Person_PageMenu_ApiEnum,
        Config_Person_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
