import { TFunction } from 'i18next';
import { padNumber } from './browser/padNumber';
import { Option } from 'components/interfaces/GeneralInterface';

export enum DatePatternEnum {
    'time' = 'time',
    'dateTime' = 'dateTime',
    'date' = 'date',
}

export const getMinDate = (): Date => new Date('1800-01-01T00:00:00');
export const getMinNumber = (): number => 1800;

const twoDigits = (value: number): string => {
    return value < 10 ? `0${value}` : value.toString();
};

export const isPastDate = (date: string): boolean =>
    new Date(date).getTime() - new Date().getTime() > 0;

export const isTodayValid = (
    start: Date | string,
    end: Date | string
): boolean => {
    const now = new Date().getTime();
    return new Date(start).getTime() < now && new Date(end).getTime() > now;
};

export const toDay = (dateString: Date | string): number =>
    new Date(dateString).getDate();

export const toMonthNamely = (
    dateString: Date | string,
    t: TFunction
): string => t(`general.month-${new Date(dateString).getMonth() + 1}`);

export const toDateWithTodayYesterday = (
    dateString: Date | string,
    t: TFunction
): string => {
    if (!dateString) {
        return null;
    }
    const date = new Date(dateString);
    if (isToday(date)) {
        return t('general.today');
    }
    if (isYesterday(date)) {
        return t('general.yesterday');
    }

    return toDate(dateString);
};

export const toDate = (dateString: Date | string): string => {
    if (!dateString) {
        return '-';
    }
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
};

export const toDateWithStrDay = (
    dateString: Date | string,
    t: TFunction
): string => {
    if (!dateString) {
        return null;
    }
    const date = new Date(dateString);
    return `${toDate(date)} (${toDayStr(date, t)})`;
};

export const toDateWithTime = (dateString: Date | string): string => {
    if (!dateString) return null;
    const date =
        typeof dateString === 'string'
            ? new Date(dateTimeFromIso(dateString))
            : dateString;
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hour = date.getHours();
    const minute = date.getMinutes();

    return `${day}.${month}.${year} ${hour}:${twoDigits(minute)}`;
};

export const toDateWithTimeWithStrDay = (
    dateString: Date | string,
    t: TFunction
): string => {
    if (!dateString) {
        return null;
    }
    const date = new Date(dateString);
    return `${toDateWithTime(date)} (${toDayStr(date, t)})`;
};

export const toDayStr = (dateString: Date | string, t: TFunction): string => {
    if (!dateString) {
        return null;
    }
    const date = new Date(dateString);
    return t(`general.day-${date.getDay()}`);
};

export const toDayShortcut = (
    dateString: Date | string,
    t: TFunction
): string => {
    if (!dateString) {
        return null;
    }
    const date = new Date(dateString);
    return t('general.dayShortcut-' + date.getDay());
};

export const toInputDate = (dateString: Date | string): string => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
};

export const toTime = (dateString: Date | string): string => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    const hour = '' + date.getHours();
    const minute = '' + date.getMinutes();
    const fixedHour = hour.length === 1 ? '0' + hour : hour;
    const fixedMinute = minute.length === 1 ? '0' + minute : minute;

    return `${fixedHour}:${fixedMinute}`;
};

export const stripIsoTime = (dateString: string): string =>
    dateString?.split('T')[0];

export const stripIsoDate = (dateString: string): string =>
    dateString?.split('T')[1];

export const startsIn = (dateString: Date | string): number => {
    const date = new Date(dateString);
    const now = new Date();

    const diffMs = date.getTime() - now.getTime();
    const diffMins = Math.round(diffMs / 86400000 / 3600000 / 60000); // minutes

    return diffMins;
};

export const toYear = (
    dateString: Date | string,
    emptyPlaceholder = '-'
): number | string => {
    if (dateString) {
        return new Date(dateString).getFullYear();
    }
    return emptyPlaceholder;
};

export const playerActiveSpan = (
    from: Date | string,
    to: Date | string
): string => {
    const fromString = new Date(from).getFullYear();
    const toString = to ? ` - ${new Date(to).getFullYear()}` : '';
    return `${fromString}${toString}`;
};

export const dateSpan = (
    from: Date | string,
    to: Date | string,
    t?: TFunction
): string => {
    if (t) {
        if (from && !to) {
            return `${t('general.from')} ${toDate(from)}`;
        }
        if (!from && to) {
            return `${t('general.to')} ${toDate(to)}`;
        }
    }
    if (!from && !to) {
        return null;
    }
    const fromString = toDate(from);
    const toString = to ? ` - ${toDate(to)}` : '';
    return `${fromString}${toString}`;
};

export const getPersonAge = (birthDate: Date | string): number => {
    const date =
        typeof birthDate === 'string' ? new Date(birthDate) : birthDate;

    const diff_ms = Date.now() - date.getTime();
    const age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
};

let timeZoneOffsetString: string = null;

export const dateTimeFromIso = (dateString: string): Date => {
    if (!dateString) return null;
    // If we have date, we are receiving date without timezone (for Safari)
    if (dateString.indexOf('T') === -1) {
        // Ensure that timezone is based on current env.
        if (timeZoneOffsetString === null) {
            // Convert the timezone to T format from -120 to 2 hours and then to +02
            const timeZoneOffset = new Date().getTimezoneOffset() / -60;
            const offsetValuePrefix = timeZoneOffset > 0 ? '+' : '-';
            const leadOffsetWithZeroAtMaxTwoChars = (
                '0' + timeZoneOffset
            ).slice(-2);
            timeZoneOffsetString =
                offsetValuePrefix + leadOffsetWithZeroAtMaxTwoChars + ':00';
        }
        dateString = dateString.replace(' ', 'T') + timeZoneOffsetString;
    }
    return new Date(Date.parse(dateString));
};

export const isDateAfter = (
    before: Date | string,
    after: Date | string,
    noErrorWhenEqual?: boolean
): boolean =>
    !!before &&
    !!after &&
    (noErrorWhenEqual
        ? new Date(before).getTime() <= new Date(after).getTime()
        : new Date(before).getTime() < new Date(after).getTime());

export const isDateAfterError = (
    t: TFunction,
    before: Date | string,
    after: Date | string,
    noErrorWhenEqual?: boolean
): string => {
    if (!before || !after) return undefined;
    return isDateAfter(before, after, noErrorWhenEqual)
        ? undefined
        : t('general.dateAfterError');
};

export const isValidDate = (d: Date | string): boolean => {
    if (d instanceof Date) {
        return !isNaN(d.getTime());
    } else if (typeof d === 'string') {
        const date = new Date(d.toString());
        return (
            !isNaN(date.getTime()) && date.getFullYear().toString().length === 4
        );
    }

    return false;
};

export const isDateInValidRange = (d: Date | string): boolean => {
    const date = d instanceof Date ? d : new Date(d).getTime();
    const from = new Date('01/01/1800').getTime();
    const to = new Date(
        new Date().setFullYear(new Date().getFullYear() + 2)
    ).getTime();

    return date >= from && date <= to;
};

export const getWeekDays = (t: TFunction): Option[] => {
    const days: Option[] = [];
    for (let i = 1; i < 7; i++) {
        days.push({
            label: t(`general.day-${i}`),
            value: i + '',
        });
    }
    days.push({
        label: t('general.day-0'),
        value: '0',
    });

    return days;
};

const compareDates = (someDate: Date, compareTo: Date): boolean => {
    return (
        someDate.getDate() == compareTo.getDate() &&
        someDate.getMonth() == compareTo.getMonth() &&
        someDate.getFullYear() == compareTo.getFullYear()
    );
};

const isToday = (someDate: Date): boolean => compareDates(someDate, new Date());

const isYesterday = (someDate: Date): boolean => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return compareDates(someDate, yesterday);
};

/** DATETIME - ISO */
export const dateToIso = (
    value: Date,
    type: DatePatternEnum = DatePatternEnum.date
): string => {
    switch (type) {
        case DatePatternEnum.dateTime:
            return dateToIsoDatetime(value);
        case DatePatternEnum.time:
            return dateToIsoTime(value);
        case DatePatternEnum.date:
            return dateToIsoDate(value);
    }
};

const dateToIsoDatetime = (dateTime: Date): string => dateTime.toISOString();

const dateToIsoDate = (dateTime: Date): string => {
    return (
        dateTime.getFullYear() +
        '-' +
        padNumber(dateTime.getMonth() + 1) +
        '-' +
        padNumber(dateTime.getDate())
    );
};

const dateToIsoTime = (dateTime: Date): string =>
    `${padNumber(dateTime.getHours())}:${padNumber(dateTime.getMinutes())}`;
