import {
    CompetitionInfoApiInterface,
    SeasonApiEnum,
    TeamApiInterface,
    PersonRoleApiInterface,
    OrganisationUnitApiInterface,
    PitchApiInterface,
    SurfaceEnum,
    PersonEntityReferenceApiInterface,
    TeamWithSummaryStatsApiInterface,
    DocumentApiInterface,
    TagApiInterface,
    ApplicationEntityReferenceApiInterface,
    ClubBaseWithDisciplineApiInterface,
} from '../ApiInterface';
import { ConfigModuleApiInterface } from '../config/ConfigApiInterface';
import { PersonBasicDataWithHasLicenceApiInterface } from './PersonInterface';
import { PPSettingsContumacyTypeEnum } from './ProceedingInterface';

export interface MatchPlayerPunishmentApiInterface {
    person_id?: number;
    type: PunishmentTypeEnum;
    match_minute: string;
    cause: PunishmentCauseEnum;
    note?: string;
}

export enum PunishmentTypeEnum {
    YELLOW = 'yellow',
    RED = 'red',
}

export enum PunishmentCauseEnum {
    raw_game = 'raw_game',
    rude_conduct = 'rude_conduct',
    spitting_on_opponent = 'spitting_on_opponent',
    disrupting_obvious_goal_potential = 'disrupting_obvious_goal_potential',
    prevent_from_scoring = 'prevent_from_scoring',
    offensive_gestures = 'offensive_gestures',
    second_reprimand_in_match = 'second_reprimand_in_match',
    other = 'other',
    nch_ruthless_offense = 'nch_ruthless_offense',
    nch_reckless_use_of_arm_in_ball_fight = 'nch_reckless_use_of_arm_in_ball_fight',
    nch_zsra_offense = 'nch_zsra_offense',
    nch_zzbm_in_pu_effort_play_ball = 'nch_zzbm_in_pu_effort_play_ball',
    nch_lack_of_respect_for_game = 'nch_lack_of_respect_for_game',
    delaying_game = 'delaying_game',
    expression_protest_with_words_or_gestures = 'expression_protest_with_words_or_gestures',
    systematic_violation_football_rules = 'systematic_violation_football_rules',
    nch_other = 'nch_other',
    throw_object_to_field = 'throw_object_to_field',
    enters_field_to_influence_game = 'enters_field_to_influence_game',
    violent_or_aggressive_behavior = 'violent_or_aggressive_behavior',
    disagreement_with_decision = 'disagreement_with_decision',
    excessive_gesticulation_to_punish = 'excessive_gesticulation_to_punish',
    provocative_outrageous_behavior = 'provocative_outrageous_behavior',
    disrespect_boundaries_of_tz = 'disrespect_boundaries_of_tz',
    enters_opponents_tz = 'enters_opponents_tz',
}

export interface MatchPlayersRecordInterface {
    order: number;
    person_id: number;
    internal_id: string;
    name: string;
    gender: string;
    age: number;
    is_captain: boolean;
    jersey_number: number;
    position: MatchPlayerPositionEnum;
    photo: string;
    rotations: RotationInterface[];
    punishments: PunishmentInterface[];
    goals: GoalInterface[];
    indications?: TagApiInterface[];
}

export interface FunctionaryInterface {
    person_id: number;
    internal_id: string;
    name: string;
    function: FunctionaryFunctionEnum;
    punishments: PunishmentInterface[];
}

export enum FunctionaryFunctionEnum {
    HEAD = 'head',
    MAIN_ORGANIZER = 'main-organizer',
    ASSISTANT_1 = 'assistant-1',
    ASSISTANT_2 = 'assistant-2',
    MEDIC = 'medic',
    TRAINER = 'trainer',
    TEAN_LEADER = 'team-leader',
    MASSEUR = 'masseur',
    GOALKEEPER_TRAINER = 'goalkeeper-trainer',
    CONDITION_TRAINER = 'condition-trainer',
    VICE_CAPTAIN = 'vice-captain',
}

export interface PunishmentInterface {
    id: number;
    person_id: number;
    type: PunishmentTypeEnum;
    cause: PunishmentCauseEnum;
    match_minute: string;
    note?: string;
    created_at?: string;
    updated_at?: string;
}

export interface RotationInterface {
    id: number;
    type: 'in' | 'out';
    person_id: number;
    person_id_opposite: number;
    match_minute: string;
    created_at?: string;
    updated_at?: string;
}

export interface GoalInterface {
    id: number;
    person_id: number;
    type: GoalTypeEnum;
    match_minute: string;
    match_part: 'first_half' | 'second_half' | 'penalty_kicks';
    created_at?: string;
    updated_at?: string;
}

export enum GoalTypeEnum {
    GOAL = 'goal',
    OWN_GOAL = 'own_goal',
    PENALTY_KICKS = 'penalty_kicks',
    PENALTY_SHOOTOUT = 'penalty_shootout',
}

export interface MatchPlayerApiInterface {
    person_id?: number;
    is_captain: boolean;
    jersey_number: number;
    position: MatchPlayerPositionEnum;
}

export interface MatchPlayerGoalApiInterface {
    person_id?: number;
    type: GoalTypeEnum;
    match_minute: string;
}

export interface MatchPlayerRotationApiInterface {
    person_id: number;
    person_id_opposite: number;
    match_minute: string;
}

export interface MatchFunctionaryApiInterface {
    person_id: number;
    function: FunctionaryFunctionEnum;
}

export interface MatchScheduleApiInterface {
    fill_assignees: MatchScheduleConentApiInterface;
    pre_start_check: MatchScheduleConentApiInterface;
    started: MatchScheduleConentApiInterface;
    record_check: MatchScheduleConentApiInterface;
    confirmed_home_team: MatchScheduleConentApiInterface;
    confirmed_guest_team: MatchScheduleConentApiInterface;
    closed: MatchScheduleConentApiInterface;
}

export interface MatchScheduleConentApiInterface {
    datetime: string;
    is_current: boolean;
    is_completed: boolean;
}

export enum TeamSideEnum {
    HOME = 'home',
    GUEST = 'guest',
}

export enum MatchPlayerPositionEnum {
    GOALKEEPER = 'goalkeeper',
    MIDFIEDER = 'midfielder',
    ATTACKER = 'attacker',
    DEFENSE = 'defense',
}

export interface ScoreApiInterface {
    home_team: number;
    guest_team: number;
}

export interface MatchApiInterface {
    id: number;
    identifier: string;
    state: number;
    round: number;
    round_position: number;
    season: SeasonApiEnum;
    home_team: TeamApiInterface;
    guest_team: TeamApiInterface;
    playing_at: string;
    round_date: string;
    pitch: PitchApiInterface;
    competition: CompetitionInfoApiInterface;
    final_score: string | null;
    score?: ScoreApiInterface;
    persons_roles: PersonRoleApiInterface[];
    record?: RecordApiInterface;
    referee_team: MatchRefereesTeamApiInterface;
    organisation_unit?: OrganisationUnitApiInterface;
    walkover_team_side: TeamSideEnum;
    note: string;
    actions?: MatchActionsApiInterface;
    contumacy_type: PPSettingsContumacyTypeEnum;
}

export interface MatchDelegationApiInterface {
    id: number;
    identifier: string;
    state: number;
    round: number;
    round_position: number;
    season: SeasonApiEnum;
    home_team: TeamApiInterface;
    guest_team: TeamApiInterface;
    playing_at: string;
    pitch: PitchApiInterface;
    competition: CompetitionInfoApiInterface;
    score?: ScoreApiInterface;
    persons_roles: PersonRoleApiInterface[];
    record?: RecordApiInterface;
    referee_team: MatchRefereesDelegationTeamApiInterface;
    organisation_unit?: OrganisationUnitApiInterface;
    walkover_team_side: TeamSideEnum;
    note: string;
    actions?: MatchActionsApiInterface;
}

export interface MatchActionsApiInterface {
    update_match: ConfigModuleApiInterface;
    delete_match: ConfigModuleApiInterface;
    export_protest: ConfigModuleApiInterface;
    ['export_protest:home']: ConfigModuleApiInterface;
    ['export_protest:guest']: ConfigModuleApiInterface;
}

export interface RecordApiInterface {
    schedule: MatchScheduleApiInterface;
    spectators_count: number;
    state: number;
    score: {
        first_half: ScoreApiInterface;
        second_half: ScoreApiInterface;
        penalty_kicks: ScoreApiInterface;
        total: ScoreApiInterface;
    };
    author: RecordAuthorApiInterface;
    first_half: string;
    second_half: string;
    closed_by_system: boolean;
    closed_at: string;
}

export interface RecordAuthorApiInterface {
    person_id: number;
    internal_id: number;
    name: string;
}

export enum RefereeApiEnum {
    main = 'main',
    ar1 = 'ar1',
    ar2 = 'ar2',
    '4r' = '4r',
    dfa = 'dfa',
    td = 'td',
    var = 'var',
    avar = 'avar',
    ra = 'ra',
}

export type MatchRefereesDelegationTeamApiInterface = Record<
    RefereeApiEnum,
    PersonBasicDataWithHasLicenceApiInterface
>;

export type MatchRefereesTeamApiInterface = Record<
    RefereeApiEnum,
    MatchRefereeApiInterface[]
>;

export interface MatchRefereeApiInterface {
    person: PersonEntityReferenceApiInterface;
    role: RefereeApiEnum;
    has_active_license: boolean;
    indications: TagApiInterface[];
}

export interface MatchesConfrimRecordApiInterface {
    confirm_code: number;
}

export enum SCHEDULE_REPORT_TYPE_ENUM {
    CONTUMACY = 'contumacy',
    NEW_PLAY_DATE = 'new_play_date',
}

export interface MatchRecordUpdateApiInterface {
    spectators_count: number;
    surface: SurfaceEnum;
    first_half: string;
    second_half: string;
}

export interface MatchCreateUpdateApiInterface {
    competition_id?: number;
    home_team_id?: number;
    guest_team_id?: number;
    pitch_id?: number;
    date_number?: number;
    round_position?: number;
    note?: string;
    playing_at?: string;
}

export interface MatchCreateEditRefereeReportApiInterface {
    type: MatchRefereeReportTypeApiEnum;
    match_minute: string;
    message: string;
    is_for_stk: boolean;
    is_for_dk: boolean;
    identity_check: boolean;
    related_to_competition_team_id: number;
    related_to_person_id: number;
}

export interface MatchRefereeReportApiInterface {
    id: number;
    type: MatchRefereeReportTypeApiEnum;
    match_minute: string;
    message: string;
    is_for_stk: boolean;
    is_for_dk: boolean;
    identity_check: boolean;
    related_to_person: PersonEntityReferenceApiInterface;
    related_to_competition_team: TeamWithSummaryStatsApiInterface;
    documents: DocumentApiInterface[];
    create_at: string;
    updated_at: string;
}

export enum MatchRefereeReportTypeApiEnum {
    injury = 'injury',
    canceled_before_match = 'canceled_before_match',
    canceled_in_match = 'canceled_in_match',
    enters_field_to_influence_game = 'enters_field_to_influence_game',
    second_reprimand_in_match = 'second_reprimand_in_match',
    prevent_from_scoring = 'prevent_from_scoring',
    violent_or_aggressive_behavior = 'violent_or_aggressive_behavior',
    disrupting_obvious_goal_potential = 'disrupting_obvious_goal_potential',
    other = 'other',
}

export interface MatchCreateDelegateReportApiInterface {
    message: string;
    is_for_stk: boolean;
    is_for_dk: boolean;
    related_to_person_id: number;
    related_to_competition_team_id: number;
}

export interface MatchDelegateReportApiInterface {
    report: MatchDelegateReportReportApiInterface | null;
    rankings: MatchDelegateReportRankingApiInterface[];
}

export interface MatchDelegateReportReportApiInterface {
    match_id: number;
    message: string;
    is_for_stk: boolean;
    is_for_dk: boolean;
    related_to_person: PersonEntityReferenceApiInterface;
    related_to_competition_team: TeamWithSummaryStatsApiInterface;
    documents?: DocumentApiInterface[];
    create_at?: string;
    updated_at?: string;
}

export enum DelegateRoleApiEnum {
    main = 'main',
    ar1 = 'ar1',
    ar2 = 'ar2',
    '4r' = '4r',
    var = 'var',
    avar = 'avar',
    ra = 'ra',
}

export interface MatchDelegateReportRankingApiInterface {
    role: DelegateRoleApiEnum;
    ranking: number;
    referee: PersonEntityReferenceApiInterface;
}

export interface MatchSaveDelegateReportRankingsApiInterface {
    rankings: MatchSaveDelegateReportRankingApiInterface[];
}

export interface MatchSaveDelegateReportRankingApiInterface {
    role: DelegateRoleApiEnum;
    ranking: number;
}

export interface MatchPlayerMoveApiInterface {
    person_id: number;
    order: number;
}

export interface MatchRefereeSetApiInterface {
    person_id: number;
    role: string;
}

export interface MatchRewardBaseApiInterface {
    flat_fee: number | string;
    transportation: number | string;
}

export interface MatchRewardApiInterface extends MatchRewardBaseApiInterface {
    referee: MatchRefereeApiInterface;
    created_at?: string;
    role: RefereeApiEnum;
}

export type MatchUpdateRewardsInterface = Record<
    RefereeApiEnum,
    MatchRewardBaseApiInterface
>;

export interface TeamProtest {
    application: ApplicationEntityReferenceApiInterface;
    reason: string;
    protest: string;
    evidence: string;
    proposed_decision: string;
    club: ClubBaseWithDisciplineApiInterface;
    created_at: string;
}

export interface MatchProtestSummaryInterface {
    match: MatchApiInterface;
    home_team_protest: TeamProtest | null;
    guest_team_protest: TeamProtest | null;
    referee_reports: MatchRefereeReportApiInterface[];
    delegate_report_exists: boolean;
}
