import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Competition_PageMenu_ApiEnum } from './CompetitionDetailConfigApiInterface';

export enum Config_CompetitionStatTeams_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_CompetitionStatTeams_Modules_ApiEnum {
    'indications' = 'indications',
    'overview' = 'overview',
    'stats_for_players' = 'stats_for_players',
    'warning' = 'warning',
}

export enum Config_CompetitionStatTeams_ActionsOther_ApiEnum {
    'edit_competition_team' = 'edit_competition_team',
}

export enum Config_CompetitionStatTeams_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_CompetitionStatTeams_ApiInterface
    extends ConfigDataInterface<
        Config_CompetitionStatTeams_Modules_ApiEnum,
        Config_CompetitionStatTeams_ActionsMenu_ApiEnum,
        Config_CompetitionStatTeams_ActionsOther_ApiEnum,
        Config_Competition_PageMenu_ApiEnum,
        Config_CompetitionStatTeams_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
