import {
    ConfigCanUpdatePenaltyKicksModule,
    ConfigCanUpdateScoreModule,
    ConfigCompetitionTeamIdsModule,
    ConfigSystemBlockModule,
    PageConfigConfirmFieldsInterface,
    PageConfigFieldsOuterInterface,
    PageConfigLabelInterface,
    PageConfigRequiredAllFieldsInterface,
} from '../api/ConfigInterface';
import {
    ConfigUserAccessModule,
    OrganisationUnitApiInterface,
    ConfigTeamsDataApiInterface,
    ConfigBergerModule,
    ConfigHasMatchesSeasonModule,
    ConfigSeasonsBergerModule,
    ConfigClubBulkMembershipModuleDataApiInterface,
    ConfigClubsDataApiInterface,
    ConfigDisabledFieldsModule,
    ConfigEnabledSeasonsModule,
    ConfigMatchesCountModule,
    ConfigTeamDataApiInterface,
    ConfigPreviousMatchModule,
    ConfigAllowedFunctionsModule,
    ConfigFlatFeeEditableModule,
    ConfigGroupsUsersGroupModuleData,
    ConfigHasProtestApplicationModule,
    ConfigPunishmentsModule,
    ConfigApplicationModule,
    ConfigEnabledIndicationsModule,
    ConfigDecisionModule,
    ConfigRegulationModule,
    ConfigEnabledModulesModule,
    ConfigPunishmentCausesModule,
    ConfigUrlModule,
    ConfigCanSeeAllOrgUnitsModule,
    ConfigExcludePersonIdsModule,
    ConfigExampleMatchesDataApiInterface,
    ConfigStateHistoryModule,
    ConfigRegDecModule,
    ConfigExportInterface,
    ProtestExportData,
} from '../ApiInterface';

type ModuleStructure<MODULES extends string> = Record<
    MODULES,
    ModuleContentInterface
>;

export interface ModuleContentInterface {
    enabled: boolean;
    editable: boolean;
    data?: DataType;
    columns?: { [key: string]: boolean };
    organisation_units?: OrganisationUnitApiInterface[];
    filters?: Record<string, boolean>;
    filters_type?: ModuleFilterTypeEnum;
    export?: { [key: string]: boolean } | null;
}

export enum ModuleFilterTypeEnum {
    full_list = 'full_list', // Controls all filters given by list of filters -> module.xxx.filters, DO NOT SHOW others!
    only_controlled = 'only_controlled', // Controls only filters given by list of filters -> module.xxx.filters, rest it uncontrolled
}

export type DataType = Partial<DataInterface>;

export interface ConfigModuleApiInterface {
    enabled: boolean;
    reason: string;
    label: string | null;
    data?: DataType;
}

export interface ConfigActionsApiInterface<
    ACTIONS_MENU extends string,
    ACTIONS_OTHER extends string
> {
    menu: Record<ACTIONS_MENU, ConfigModuleApiInterface>;
    other: Record<ACTIONS_OTHER, ConfigModuleApiInterface>;
}

export interface PageConfigDataInterface<
    MODULES extends string,
    ACTIONS_MENU extends string,
    ACTIONS_OTHER extends string
> {
    modules: ModuleStructure<MODULES>;
    actions: ConfigActionsApiInterface<ACTIONS_MENU, ACTIONS_OTHER>;
}

export interface ConfigDataInterface<
    MODULES extends string,
    ACTIONS_MENU extends string,
    ACTIONS_OTHER extends string,
    PAGE_MENU extends string,
    PAGE_SUB_MENU extends string
> {
    page: PageConfigDataInterface<MODULES, ACTIONS_MENU, ACTIONS_OTHER>;
    pageMenu: PAGE_MENU[];
    pageSubMenu: PAGE_SUB_MENU[];
}

export interface ConfigPageDataInterface<
    MODULES extends string,
    ACTIONS_MENU extends string,
    ACTIONS_OTHER extends string,
    PAGE_MENU extends string,
    PAGE_SUB_MENU extends string
> {
    config: ConfigDataInterface<
        MODULES,
        ACTIONS_MENU,
        ACTIONS_OTHER,
        PAGE_MENU,
        PAGE_SUB_MENU
    >;
}
interface DataInterface
    extends ConfigClubBulkMembershipModuleDataApiInterface,
        ConfigTeamDataApiInterface,
        ConfigTeamsDataApiInterface,
        ConfigClubsDataApiInterface,
        ConfigDisabledFieldsModule,
        PageConfigFieldsOuterInterface,
        ConfigExportInterface<ProtestExportData>,
        ConfigSystemBlockModule,
        ConfigBergerModule,
        ConfigAllowedFunctionsModule,
        ConfigEnabledSeasonsModule,
        ConfigEnabledModulesModule,
        ConfigMatchesCountModule,
        ConfigHasMatchesSeasonModule,
        ConfigSeasonsBergerModule,
        ConfigPreviousMatchModule,
        ConfigFlatFeeEditableModule,
        ConfigUserAccessModule,
        ConfigHasProtestApplicationModule,
        PageConfigConfirmFieldsInterface,
        PageConfigRequiredAllFieldsInterface,
        PageConfigLabelInterface,
        ConfigPunishmentsModule,
        ConfigApplicationModule,
        ConfigEnabledIndicationsModule,
        ConfigDecisionModule,
        ConfigRegulationModule,
        ConfigPunishmentCausesModule,
        ConfigCanSeeAllOrgUnitsModule,
        ConfigUrlModule,
        ConfigExcludePersonIdsModule,
        ConfigExampleMatchesDataApiInterface,
        ConfigStateHistoryModule,
        ConfigCanUpdateScoreModule,
        ConfigCanUpdatePenaltyKicksModule,
        ConfigCompetitionTeamIdsModule,
        ConfigRegDecModule,
        ConfigGroupsUsersGroupModuleData {}
