import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_EMPTY_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_EMPTY_Modules_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_EMPTY_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_EMPTY_PageMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_EMPTY_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_EMPTY_ApiInterface
    extends ConfigDataInterface<
        Config_EMPTY_Modules_ApiEnum,
        Config_EMPTY_ActionsMenu_ApiEnum,
        Config_EMPTY_ActionsOther_ApiEnum,
        Config_EMPTY_PageMenu_ApiEnum,
        Config_EMPTY_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
