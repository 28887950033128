import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Person_PageMenu_ApiEnum } from './PersonDetailConfigApiInterface';

export enum Config_Delegation_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_Delegation_Modules_ApiEnum {
    'results' = 'results',
}

export enum Config_Delegation_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_Delegation_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_Delegation_ApiInterface
    extends ConfigDataInterface<
        Config_Delegation_Modules_ApiEnum,
        Config_Delegation_ActionsMenu_ApiEnum,
        Config_Delegation_ActionsOther_ApiEnum,
        Config_Person_PageMenu_ApiEnum,
        Config_Delegation_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
