import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Person_PageMenu_ApiEnum } from './PersonDetailConfigApiInterface';

export enum Config_PlayerMatches_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_PlayerMatches_Modules_ApiEnum {
    'indications' = 'indications',
    'overview' = 'overview',
    'matches' = 'matches',
}

export enum Config_PlayerMatches_ActionsOther_ApiEnum {
    'upload_photo' = 'upload_photo',
}

export enum Config_PlayerMatches_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_PlayerMatches_ApiInterface
    extends ConfigDataInterface<
        Config_PlayerMatches_Modules_ApiEnum,
        Config_PlayerMatches_ActionsMenu_ApiEnum,
        Config_PlayerMatches_ActionsOther_ApiEnum,
        Config_Person_PageMenu_ApiEnum,
        Config_PlayerMatches_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
