import {
    OrganizationUnitWithRolesApiInterface,
    DisciplineEnum,
    ClubBaseWithDisciplineApiInterface,
} from '../ApiInterface';
import { ClubPersonRolesApiEnum } from './ClubInterface';
import {
    GenderEnum,
    MemberApiInterface,
    PersonDataApiInterface,
} from './PersonInterface';

export interface InternalUserListApiInterface {
    user: InternalUserApiInterface;
    person: PersonDataApiInterface;
    member: MemberApiInterface;
}

export interface InternalUserApiInterface {
    id: number;
    email: string;
    email_verified_at: string;
    created_at: string;
    updated_at: string;
    groups: Record<DisciplineEnum, UserRoleApiEnum[]>;
}

export interface InternalUserDetailApiInterface {
    id: number;
    email: string;
    verified_at: string;
    created_at: string;
    updated_at: string;
    organisation_unit_roles: OrganizationUnitWithRolesApiInterface[];
    groups: InternalUserGroupApiInterface[];
    club_roles: InternalUserClubRolesApiInterface[];
}

export interface InternalUserGroupApiInterface {
    group_key: UserRoleApiEnum;
    discipline: DisciplineEnum;
}

export interface InternalUserClubRolesApiInterface {
    club: ClubBaseWithDisciplineApiInterface;
    roles: ClubPersonRolesApiEnum[];
}

export enum UserRoleApiEnum {
    memberships_admin_group = 'memberships_admin_group',
    player_registration_admin_group = 'player_registration_admin_group',
    subsidy_department_group = 'subsidy_department_group',
    financial_department_group = 'financial_department_group',
    notice_board_admin_group = 'notice_board_admin_group',
    users_registration_admin_group = 'users_registration_admin_group',
    organisation_units_registration_group = 'organisation_units_registration_group',
    competitions_admin_group = 'competitions_admin_group',
    data_protection_group = 'data_protection_group',
    agent_department_group = 'agent_department_group',
    super_admin_group = 'super_admin_group',
}

export interface CreateUserApiInterface {
    fields: {
        title: string;
        first_name: string;
        middle_name: string;
        last_name: string;
        email: string;
        phone: string;
        gender: GenderEnum;
    };
    user: { password: string };
}

export interface CreateUserFromPersonApiInterface {
    person_id: number;
    phone: string;
    email: string;
}
