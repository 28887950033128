import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_ClubDetail_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_ClubDetail_Modules_ApiEnum {
    'indications' = 'indications',
    'overview' = 'overview',
    'contacts' = 'contacts',
    'bank' = 'bank',
    'organization_worker' = 'organization_worker',
    'shipping' = 'shipping',
    'matches' = 'matches',
    'system' = 'system',
}

export enum Config_ClubDetail_ActionsOther_ApiEnum {
    'upload_photo' = 'upload_photo',
}

export enum Config_Club_PageMenu_ApiEnum {
    'club-detail' = 'club-detail',
    'club-competitions' = 'club-competitions',
    'club-control' = 'club-control',
    'club-account-subsidies' = 'club-account-subsidies',
    'club-account-transactions' = 'club-account-transactions',
    'club-account-issued-documents' = 'club-account-issued-documents',
    'club-licencses' = 'club-licencses',
    'club-matches' = 'club-matches',
    'club-persons' = 'club-persons',
    'club-players' = 'club-players',
    'club-requests' = 'club-requests',
    'club-applications' = 'club-applications',
    'club-bulk-memberships' = 'club-bulk-memberships',
    'club-pitches' = 'club-pitches',
    'club-signatures' = 'club-signatures',
    'club-players-registrations' = 'club-players-registrations',
}

export enum Config_Club_PageSubMenu_ApiEnum {
    'club-competition' = 'club-competition',
    'club-competition-team-list-players-autumn' = 'club-competition-team-list-players-autumn',
    'club-competition-team-list-players-spring' = 'club-competition-team-list-players-spring',
}

export interface Config_ClubDetail_ApiInterface
    extends ConfigDataInterface<
        Config_ClubDetail_Modules_ApiEnum,
        Config_ClubDetail_ActionsMenu_ApiEnum,
        Config_ClubDetail_ActionsOther_ApiEnum,
        Config_Club_PageMenu_ApiEnum,
        Config_Club_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
