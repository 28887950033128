import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Person_PageMenu_ApiEnum } from './PersonDetailConfigApiInterface';

export enum Config_PersonFields_ActionsMenu_ApiEnum {
    'archive' = 'archive',
    'add_address' = 'add_address',
}

export enum Config_PersonFields_Modules_ApiEnum {
    'indications' = 'indications',
    'overview' = 'overview',
    'contacts' = 'contacts',
    'name' = 'name',
    'addresses' = 'addresses',
    'other' = 'other',
}

export enum Config_PersonFields_ActionsOther_ApiEnum {
    'upload_photo' = 'upload_photo',
}

export enum Config_PersonFields_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_PersonFields_ApiInterface
    extends ConfigDataInterface<
        Config_PersonFields_Modules_ApiEnum,
        Config_PersonFields_ActionsMenu_ApiEnum,
        Config_PersonFields_ActionsOther_ApiEnum,
        Config_Person_PageMenu_ApiEnum,
        Config_PersonFields_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
