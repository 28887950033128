import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_ProceedingsStkDK_ActionsMenu_ApiEnum {
    'create_proceeding' = 'create_proceeding',
}

export enum Config_ProceedingsStkDK_Modules_ApiEnum {
    'proceedings' = 'proceedings',
}

export enum Config_ProceedingsStkDK_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_ProceedingsStkDK_PageMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_ProceedingsStkDK_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_ProceedingsStkDK_ApiInterface
    extends ConfigDataInterface<
        Config_ProceedingsStkDK_Modules_ApiEnum,
        Config_ProceedingsStkDK_ActionsMenu_ApiEnum,
        Config_ProceedingsStkDK_ActionsOther_ApiEnum,
        Config_ProceedingsStkDK_PageMenu_ApiEnum,
        Config_ProceedingsStkDK_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
