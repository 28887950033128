import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_LegalPersonCreate_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_LegalPersonCreate_Modules_ApiEnum {
    'form' = 'form',
}

export enum Config_LegalPersonCreate_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_LegalPersonCreate_PageMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_LegalPersonCreate_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_LegalPersonCreate_ApiInterface
    extends ConfigDataInterface<
        Config_LegalPersonCreate_Modules_ApiEnum,
        Config_LegalPersonCreate_ActionsMenu_ApiEnum,
        Config_LegalPersonCreate_ActionsOther_ApiEnum,
        Config_LegalPersonCreate_PageMenu_ApiEnum,
        Config_LegalPersonCreate_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
