import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Club_PageMenu_ApiEnum } from './ClubDetailConfigApiInterface';

export enum Config_ClubSignatures_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_ClubSignatures_Modules_ApiEnum {
    'indications' = 'indications',
    'overview' = 'overview',
    'signatures' = 'signatures',
}

export enum Config_ClubSignatures_ActionsOther_ApiEnum {
    'upload_photo' = 'upload_photo',
}

export enum Config_ClubSignatures_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_ClubSignatures_ApiInterface
    extends ConfigDataInterface<
        Config_ClubSignatures_Modules_ApiEnum,
        Config_ClubSignatures_ActionsMenu_ApiEnum,
        Config_ClubSignatures_ActionsOther_ApiEnum,
        Config_Club_PageMenu_ApiEnum,
        Config_ClubSignatures_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
