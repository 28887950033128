import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_RewardsPrices_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_RewardsPrices_Modules_ApiEnum {
    'results' = 'results',
}

export enum Config_RewardsPrices_ActionsOther_ApiEnum {
    'create_reward' = 'create_reward',
}

export enum Config_RewardsPrices_PageMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_RewardsPrices_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_RewardsPrices_ApiInterface
    extends ConfigDataInterface<
        Config_RewardsPrices_Modules_ApiEnum,
        Config_RewardsPrices_ActionsMenu_ApiEnum,
        Config_RewardsPrices_ActionsOther_ApiEnum,
        Config_RewardsPrices_PageMenu_ApiEnum,
        Config_RewardsPrices_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
