import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_Subsidies_ActionsMenu_ApiEnum {
    'create_subsidy' = 'create_subsidy',
}

export enum Config_Subsidies_Modules_ApiEnum {
    'results' = 'results',
}

export enum Config_Subsidies_ActionsOther_ApiEnum {
    'create' = 'create',
}

export enum Config_Subsidies_PageMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_Subsidies_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_Subsidies_ApiInterface
    extends ConfigDataInterface<
        Config_Subsidies_Modules_ApiEnum,
        Config_Subsidies_ActionsMenu_ApiEnum,
        Config_Subsidies_ActionsOther_ApiEnum,
        Config_Subsidies_PageMenu_ApiEnum,
        Config_Subsidies_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
