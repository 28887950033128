import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Club_PageMenu_ApiEnum } from './ClubDetailConfigApiInterface';

export enum Config_ClubBulkMembershipDetail_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_ClubBulkMembershipDetail_Modules_ApiEnum {
    'overview' = 'overview',
    'members' = 'members',
    'payment' = 'payment',
    'system' = 'system',
}

export enum Config_ClubBulkMembershipDetail_ActionsOther_ApiEnum {
    'upload_photo' = 'upload_photo',
}

export enum Config_ClubBulkMembershipDetail_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_ClubBulkMembershipDetail_ApiInterface
    extends ConfigDataInterface<
        Config_ClubBulkMembershipDetail_Modules_ApiEnum,
        Config_ClubBulkMembershipDetail_ActionsMenu_ApiEnum,
        Config_ClubBulkMembershipDetail_ActionsOther_ApiEnum,
        Config_Club_PageMenu_ApiEnum,
        Config_ClubBulkMembershipDetail_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
