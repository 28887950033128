import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_OrganisationUnitDetail_PageMenu_ApiEnum } from './OrganisationUnitDetailConfigApiInterface';

export enum Config_OrganisationUnitTransactions_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_OrganisationUnitTransactions_Modules_ApiEnum {
    'overview' = 'overview',
    'results' = 'results',
}

export enum Config_OrganisationUnitTransactions_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_OrganisationUnitTransactions_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_OrganisationUnitTransactions_ApiInterface
    extends ConfigDataInterface<
        Config_OrganisationUnitTransactions_Modules_ApiEnum,
        Config_OrganisationUnitTransactions_ActionsMenu_ApiEnum,
        Config_OrganisationUnitTransactions_ActionsOther_ApiEnum,
        Config_OrganisationUnitDetail_PageMenu_ApiEnum,
        Config_OrganisationUnitTransactions_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
