import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Person_PageMenu_ApiEnum } from './PersonDetailConfigApiInterface';

export enum Config_RefereeLicences_ActionsMenu_ApiEnum {
    'archive' = 'archive',
    'deactivate_licenses' = 'deactivate_licenses',
    'create_license' = 'create_license',
}

export enum Config_RefereeLicences_Modules_ApiEnum {
    'indications' = 'indications',
    'overview' = 'overview',
    'licenses' = 'licenses',
}

export enum Config_RefereeLicences_ActionsOther_ApiEnum {
    'upload_photo' = 'upload_photo',
}

export enum Config_RefereeLicences_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_RefereeLicences_ApiInterface
    extends ConfigDataInterface<
        Config_RefereeLicences_Modules_ApiEnum,
        Config_RefereeLicences_ActionsMenu_ApiEnum,
        Config_RefereeLicences_ActionsOther_ApiEnum,
        Config_Person_PageMenu_ApiEnum,
        Config_RefereeLicences_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
