import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_CompetitionList_ActionsMenu_ApiEnum {
    'create_competition' = 'create_competition',
}

export enum Config_CompetitionList_Modules_ApiEnum {
    'results' = 'results',
}

export enum Config_CompetitionList_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_CompetitionList_PageMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_CompetitionList_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_CompetitionList_ApiInterface
    extends ConfigDataInterface<
        Config_CompetitionList_Modules_ApiEnum,
        Config_CompetitionList_ActionsMenu_ApiEnum,
        Config_CompetitionList_ActionsOther_ApiEnum,
        Config_CompetitionList_PageMenu_ApiEnum,
        Config_CompetitionList_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
