import { Config_Competition_PageMenu_ApiEnum } from './CompetitionDetailConfigApiInterface';
import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_CompetitionCreate_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_CompetitionCreate_Modules_ApiEnum {
    'create_form' = 'create_form',
}

export enum Config_CompetitionCreate_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_CompetitionCreate_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_CompetitionCreate_ApiInterface
    extends ConfigDataInterface<
        Config_CompetitionCreate_Modules_ApiEnum,
        Config_CompetitionCreate_ActionsMenu_ApiEnum,
        Config_CompetitionCreate_ActionsOther_ApiEnum,
        Config_Competition_PageMenu_ApiEnum,
        Config_CompetitionCreate_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
