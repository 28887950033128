import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Club_PageMenu_ApiEnum } from './ClubDetailConfigApiInterface';

export enum Config_ClubTransfer_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_ClubTransfer_Modules_ApiEnum {
    'overview' = 'overview',
    'indications' = 'indications',
    'declaration' = 'declaration',
}

export enum Config_ClubTransfer_ActionsOther_ApiEnum {
    'upload_photo' = 'upload_photo',
    'fill_transfer_declaration' = 'fill_transfer_declaration',
    'declaration_approve' = 'declaration_approve',
    'save' = 'save',
    'return_transfer_declaration' = 'return_transfer_declaration',
    'send_transfer_declaration_to_review' = 'send_transfer_declaration_to_review',
}

export enum Config_ClubTransfer_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_ClubTransfer_ApiInterface
    extends ConfigDataInterface<
        Config_ClubTransfer_Modules_ApiEnum,
        Config_ClubTransfer_ActionsMenu_ApiEnum,
        Config_ClubTransfer_ActionsOther_ApiEnum,
        Config_Club_PageMenu_ApiEnum,
        Config_ClubTransfer_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
