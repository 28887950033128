import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_MatchesList_ActionsMenu_ApiEnum {
    'generate_example_match' = 'generate_example_match',
}

export enum Config_MatchesList_Modules_ApiEnum {
    'results' = 'results',
}

export enum Config_MatchesList_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_MatchesList_PageMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_MatchesList_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_MatchesList_ApiInterface
    extends ConfigDataInterface<
        Config_MatchesList_Modules_ApiEnum,
        Config_MatchesList_ActionsMenu_ApiEnum,
        Config_MatchesList_ActionsOther_ApiEnum,
        Config_MatchesList_PageMenu_ApiEnum,
        Config_MatchesList_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
