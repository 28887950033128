import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_LegalPerson_PageMenu_ApiEnum } from './LegalPersonDetailConfigApiInterface';
import { Config_Person_PageMenu_ApiEnum } from './PersonDetailConfigApiInterface';

export enum Config_AgentsInsuranceDetail_ActionsMenu_ApiEnum {
    'update_agent_insurance' = 'update_agent_insurance',
}

export enum Config_AgentsInsuranceDetail_Modules_ApiEnum {
    'overview' = 'overview',
    'indications' = 'indications',
    'data' = 'data',
    'documents' = 'documents',
}

export enum Config_AgentsInsuranceDetail_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_AgentsInsuranceDetail_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_AgentsInsuranceDetail_ApiInterface
    extends ConfigDataInterface<
        Config_AgentsInsuranceDetail_Modules_ApiEnum,
        Config_AgentsInsuranceDetail_ActionsMenu_ApiEnum,
        Config_AgentsInsuranceDetail_ActionsOther_ApiEnum,
        Config_LegalPerson_PageMenu_ApiEnum | Config_Person_PageMenu_ApiEnum,
        Config_AgentsInsuranceDetail_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
