import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_LegalPersonDetail_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_LegalPersonDetail_Modules_ApiEnum {
    'overview' = 'overview',
    'contacts' = 'contacts',
    'bank' = 'bank',
    'shipping' = 'shipping',
    'system' = 'system',
    'indications' = 'indications',
}

export enum Config_LegalPersonDetail_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_LegalPerson_PageMenu_ApiEnum {
    'legal-person-detail' = 'legal-person-detail',
    'legal-person-agent-detail' = 'legal-person-agent-detail',
    'legal-person-agent-contracts' = 'legal-person-agent-contracts',
    'legal-person-agent-insurances' = 'legal-person-agent-insurances',
}

export enum Config_LegalPerson_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_LegalPersonDetail_ApiInterface
    extends ConfigDataInterface<
        Config_LegalPersonDetail_Modules_ApiEnum,
        Config_LegalPersonDetail_ActionsMenu_ApiEnum,
        Config_LegalPersonDetail_ActionsOther_ApiEnum,
        Config_LegalPerson_PageMenu_ApiEnum,
        Config_LegalPerson_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
