/**
 * Pad 0 if the number is lower than 10
 * @param number
 * @return {string|*}
 */
export const padNumber = (number: number): string => {
    if (number < 10) {
        return '0' + number;
    }

    return '' + number;
};
