import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_LegalPerson_PageMenu_ApiEnum } from './LegalPersonDetailConfigApiInterface';
import { Config_Person_PageMenu_ApiEnum } from './PersonDetailConfigApiInterface';

export enum Config_AgentsDetail_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_AgentsDetail_Person_Modules_ApiEnum {
    'overview' = 'overview',
    'contracts' = 'contracts',
    'insurances' = 'insurances',
    'indications' = 'indications',
}

export enum Config_AgentsDetail_LegalPerson_Modules_ApiEnum {
    'overview' = 'overview',
    'contracts' = 'contracts',
    'insurances' = 'insurances',
}

export enum Config_AgentsDetail_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_AgentsDetail_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_AgentsDetail_ApiInterface
    extends ConfigDataInterface<
        | Config_AgentsDetail_Person_Modules_ApiEnum
        | Config_AgentsDetail_LegalPerson_Modules_ApiEnum,
        Config_AgentsDetail_ActionsMenu_ApiEnum,
        Config_AgentsDetail_ActionsOther_ApiEnum,
        Config_LegalPerson_PageMenu_ApiEnum | Config_Person_PageMenu_ApiEnum,
        Config_AgentsDetail_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
