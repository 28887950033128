import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Competition_PageMenu_ApiEnum } from './CompetitionDetailConfigApiInterface';

export enum Config_CompetitionChangeRequests_ActionsMenu_ApiEnum {
    'create_global_change_request' = 'create_global_change_request',
}

export enum Config_CompetitionChangeRequests_Modules_ApiEnum {
    'indications' = 'indications',
    'overview' = 'overview',
    'global_change_requests' = 'global_change_requests',
    'match_change_requests' = 'match_change_requests',
    'create_competition_global_request' = 'create_competition_global_request',
    'warning' = 'warning',
}

export enum Config_CompetitionChangeRequests_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_CompetitionChangeRequests_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_CompetitionChangeRequests_ApiInterface
    extends ConfigDataInterface<
        Config_CompetitionChangeRequests_Modules_ApiEnum,
        Config_CompetitionChangeRequests_ActionsMenu_ApiEnum,
        Config_CompetitionChangeRequests_ActionsOther_ApiEnum,
        Config_Competition_PageMenu_ApiEnum,
        Config_CompetitionChangeRequests_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
