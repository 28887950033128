import {
    ContactApiInterface,
    GenderEnum,
    PersonBasicDataApiInterface,
    PersonLicenseTypeApiEnum,
    RoleApiEnum,
} from './api/PersonInterface';
import { ClubPersonRolesApiEnum } from './api/ClubInterface';
import {
    FunctionaryFunctionEnum,
    PunishmentCauseEnum,
    RefereeApiEnum,
} from './api/MatchInterface';
import { UserRoleApiEnum } from './api/UserInterface';
import {
    ProceedingPunishmentTypeEnum,
    ProceedingTypeApiEnum,
} from './api/ProceedingInterface';
import {
    CompetitionTeamApiInterface,
    ConfigModuleApiInterface,
    DeliveryType,
} from 'submodules/api_middleware';

export * from './api/AuthenticationInterface';
export * from './api/PersonInterface';
export * from './api/MatchInterface';
export * from './api/ClubInterface';
export * from './api/ClubPitchesInterface';
export * from './api/ClubSignaturesInterface';
export * from './api/ClubUnionInterface';
export * from './api/UserInterface';
export * from './api/SubsidyInterface';
export * from './api/AccountDocumentInterface';
export * from './api/AccountTransactionInterface';
export * from './api/AccountSubsidyInterface';
export * from './api/ProceedingInterface';
export * from './api/AssociationInterface';
export * from './api/AgentContractInterface';
export * from './api/PlayerInterface';
export * from './api/CompetitionInterface';
export * from './api/ConfigInterface';
export * from './api/ApplicationInterface';
export * from './api/AuditLogInterface';
export * from './api/LegalPersonInterface';
export * from './api/AlertInterface';

export interface TagApiInterface {
    key: string;
    value: string;
    title?: string;
    label?: string;
    link?: string;
    bcgColor: string;
    color?: string;
    data?: any;
}

export interface ExportUrlApiInterface {
    url: string;
}

export interface ExportEmailApiInterface {
    email: string;
}

export interface ExportErrorApiInterface {
    errorKey: string;
}

export type ExportApiType =
    | ExportUrlApiInterface
    | ExportEmailApiInterface
    | ExportErrorApiInterface;

export enum LicenseRoleEnum {
    'coach' = 'coach',
    'referee' = 'referee',
    'delegate' = 'delegate',
}

export interface ClubRepresentativePayloadApiInterface {
    first_name: string | null;
    last_name: string;
    address: AddressFullApiInterface;
    contacts: {
        emails: string[];
        phones: [];
    };
}

export enum RecipientRoleApiEnum {
    'person' = 'person',
    'organisation' = 'organisation',
    'club' = 'club',
    'role' = 'role',
    'group' = 'group',
}

export interface AddressFullApiInterface {
    id?: number;
    street: string;
    street_number: string;
    postal_code: string;
    city: string;
    country_code: string;
    phone?: string;
    email?: string;
    mobile?: string;
    email2?: string;
    type?: DeliveryType;
    contacts?: ContactApiInterface[];
    phones?: string[];
    emails?: string[];
    first_line?: string;
}

export interface LegalPersonDetailsPayloadApiInterface {
    name?: string;
    internal_id?: string;
    ico?: string;
    vat?: string;
    bank_account?: {
        country_code?: string;
        code?: number;
        number?: string;
        bank_code?: string;
        iban?: string;
        swift?: string;
        beneficiary?: string;
    };
    double_entry_accounting?: boolean;
    contacts?: {
        emails?: string[];
        phones?: string[];
    };
    address?: AddressFullApiInterface;
    shipping: {
        type: DeliveryType;
        address: AddressApiInterface;
        email: string;
    };
}

/** A paginator used on list pages. It is deserialized from MetaLinksObjectResult.MetaResult sent by C# code. */
export interface PaginatorInterface {
    current_page: number;
    last_page: number;
    total: number;
}

export interface ResponseApiSimple {
    message: string;
    error: string | string[];
    errors?: Record<string, string[]>;
}

export interface ResponseWithIdSimpleApi {
    id: number;
}

export interface ResponseWithIdApi {
    data: ResponseWithIdSimpleApi;
}

export interface ConfigUserAccessModule {
    has_user_account: boolean;
}

export interface ConfigHasProtestApplicationModule {
    has_protest_application: boolean;
}

export interface ConfigEnabledIndicationsModule {
    enabled_indications: Record<string, boolean>;
}

export interface ConfigPunishmentsModule {
    punishments: ConfigPunishmentsDataModule[];
}
export interface ConfigPunishmentsDataModule {
    id: number;
    type: ProceedingPunishmentTypeEnum;
}

export interface ConfigApplicationModule {
    application: ConfigApplicationDataModule;
}

export interface ConfigApplicationDataModule {
    id: number;
    state: ApplicationStateApiEnum;
    current_state: ApplicationStateApiEnum;
    current_state_label?: string | null;
    type: ApplicationTypeApiEnum;
}
export interface ConfigGroupsUsersGroupModuleData {
    groups: {
        group_key: UserRoleApiEnum;
        discipline: DisciplineEnum;
        is_admin: true;
    }[];
}

export interface ConfigDisabledFieldsModule {
    disabled_fields: string[];
}

export interface ConfigBergerModule {
    berger_enabled: boolean;
}

export interface ConfigSeeAllModule {
    see_all: boolean;
}

export interface ConfigCanSeeAllOrgUnitsModule {
    can_see_all_organisation_units: boolean;
}

export interface ConfigRegDecModule {
    registration_id: number;
    declaration_id: number;
}

export interface ConfigExcludePersonIdsModule {
    existing_person_ids: number[];
}

export interface ConfigDecisionModule extends ConfigSeeAllModule {
    sender_type: string[];
}

export interface ConfigRegulationModule extends ConfigSeeAllModule {
    origin_type: string[];
}

export interface ConfigUrlModule {
    url: string;
}

export interface ConfigFlatFeeEditableModule {
    flat_fee_editable: boolean;
}

export interface ConfigAllowedFunctionsModule {
    allowed_functions: FunctionaryFunctionEnum[];
}

export interface ConfigPreviousMatchModule {
    previous_match: ConfigPreviousMatchDataModule;
}

export interface ConfigPunishmentCausesModule {
    punishment_causes: ConfigPunishmentCausesModuleType;
}

export type ConfigPunishmentCausesModuleType = Record<
    ConfigPunishmentCausesModuleEnum,
    ConfigPunishmentCausesDataTypeModule
>;

export enum ConfigPunishmentCausesModuleEnum {
    'match_person' = 'match_person',
    'match_players' = 'match_players',
}

export enum ConfigPunishmentCausesDataTypeModuleEnum {
    'red' = 'red',
    'yellow' = 'yellow',
}

export type ConfigPunishmentCausesDataTypeModule = Record<
    ConfigPunishmentCausesDataTypeModuleEnum,
    PunishmentCauseEnum[]
>;

export interface ConfigPreviousMatchDataModule {
    id: number;
    identifier: string;
}

export interface ConfigHasMatchesModule {
    has_matches: boolean;
}

export interface ConfigEnabledSeasonsModule {
    enabled_seasons: SeasonApiEnum[];
}

export interface ConfigEnabledModulesModule {
    enabled_modules: Record<ConfigEnabledModulesEnum, boolean>;
}

export enum ConfigEnabledModulesEnum {
    'match_persons_guest' = 'match_persons_guest',
    'match_persons_home' = 'match_persons_home',
    'match_players_guest' = 'match_players_guest',
    'match_players_home' = 'match_players_home',
    'match_referee_report' = 'match_referee_report',
    'match_delegate_report' = 'match_delegate_report',
    'match_rewards' = 'match_rewards',
}

export interface ConfigMatchesCountModule {
    matches_count: {
        autumn: number;
        spring: number;
    };
}

export type ConfigHasMatchesSeasonModule = ConfigHasMatchesModule &
    ConfigEnabledSeasonsModule;

export type ConfigSeasonsBergerModule = ConfigBergerModule &
    ConfigEnabledSeasonsModule;

export interface ConfigTeamDataApiInterface {
    team: CompetitionTeamApiInterface;
}

export interface ConfigTeamsDataApiInterface {
    teams: CompetitionTeamApiInterface[];
}

export interface ConfigClubsDataApiInterface {
    clubs: ClubBaseWithDisciplineApiInterface[];
}

export interface ConfigClubBulkMembershipModuleDataApiInterface {
    bank_account: string;
}

export interface ObjectStringInterface {
    [key: string]: string;
}

export interface ResponseWithPaginatorInterface<T> {
    meta: PaginatorInterface;
    data: T;
}

export interface ResponseWithDataInterface<T> {
    data: T;
    status?: number; // TODO could/should not be optional, check npm build once changing
}

export interface RewardsPriceAmountApiInterface {
    role: RefereeApiEnum;
    amount?: number | string;
}

export interface RewardsPriceApiInterface {
    id: number;
    name: string;
    amounts: RewardsPriceAmountApiInterface[];
}

export enum FieldTypeApiEnum {
    'SELECT' = 'SELECT',
    'DATE' = 'DATE',
    'TIME' = 'TIME',
    'CHECKBOX' = 'CHECKBOX',
    'RADIO' = 'RADIO',
    'TEXT' = 'TEXT',
    'EMAIL' = 'EMAIL',
    'KEY-VALUE' = 'KEY-VALUE',
    'TEXTAREA' = 'TEXTAREA',
    'NUMBER' = 'NUMBER',
    'AUTOCOMPLETE' = 'AUTOCOMPLETE',
    'PASSWORD' = 'PASSWORD',
    'BREAK' = 'BREAK',
    'LABEL' = 'LABEL',
    'HEADING' = 'HEADING',
    'ADDBUTTON' = 'ADDBUTTON',
    'BUTTON' = 'BUTTON',
    'SELECT_MULTIPLE' = 'SELECT_MULTIPLE',
    'SELECT_MULTIPLE_TABLE' = 'SELECT_MULTIPLE_TABLE',
    'FILE' = 'FILE',
    'EMPTY' = 'EMPTY',
    'IMAGE' = 'IMAGE',
    'DATETIME' = 'DATETIME',
    'INFO' = 'INFO',
    'TABLE_SELECT' = 'TABLE_SELECT',
    'TABLE_ENTITIES' = 'TABLE_ENTITIES',
    'PHONE' = 'PHONE',
    'REACT_DOM' = 'REACT_DOM',
    'GROUP_BY' = 'GROUP_BY',
    'SUBSUBMENU' = 'SUBSUBMENU',
}

export enum FieldAutoCompleteTypeApiEnum {
    'PERSON-AUTOCOMPLETE' = 'PERSON-AUTOCOMPLETE',
    'ORGANISATION-UNIT-AUTOCOMPLETE' = 'ORGANISATION-UNIT-AUTOCOMPLETE',
    'LEGAL-PERSON-AUTOCOMPLETE' = 'LEGAL-PERSON-AUTOCOMPLETE',
    'CLUB-AUTOCOMPLETE' = 'CLUB-AUTOCOMPLETE',
    'AGENT-AUTOCOMPLETE' = 'AGENT-AUTOCOMPLETE',
}

export enum FieldMultipleAutoCompleteTypeApiEnum {
    'CLUB-MULTIPLE-AUTOCOMPLETE' = 'CLUB-MULTIPLE-AUTOCOMPLETE',
    'CLUB-PLAYER-MULTIPLE-AUTOCOMPLETE' = 'CLUB-PLAYER-MULTIPLE-AUTOCOMPLETE',
    'CLUB-PERSON-MULTIPLE-AUTOCOMPLETE' = 'CLUB-PERSON-MULTIPLE-AUTOCOMPLETE',
    'CLUB-COMPETITION-TEAMS-MULTIPLE-AUTOCOMPLETE' = 'CLUB-COMPETITION-TEAMS-MULTIPLE-AUTOCOMPLETE',
}

export enum FieldTextApplicationTypeApiEnum {
    'EMAIL' = 'EMAIL',
    'CZ_POSTAL_CODE' = 'CZ_POSTAL_CODE',
    'ICO' = 'ICO',
    'VAT' = 'VAT',
    'POSTAL_CODE' = 'POSTAL_CODE',
}
export enum FieldNumberApplicationTypeApiEnum {
    'MONEY' = 'MONEY',
}

export enum FieldReadOnlyTypeApiEnum {
    'MATCH' = 'MATCH',
    'COMPETITION' = 'COMPETITION',
    'COMPETITION-TEAM' = 'COMPETITION-TEAM',
    'PITCH' = 'PITCH',
    'PROCEEDING' = 'PROCEEDING',
    'ACCOUNT_TRANSACTION' = 'ACCOUNT_TRANSACTION',
}

export enum FieldSelectTypeApiEnum {
    'LANGUAGE-SELECT' = 'LANGUAGE-SELECT',
    'COUNTRY-SELECT' = 'COUNTRY-SELECT',
    'USER-CLUB-SELECT' = 'USER-CLUB-SELECT',
}

export interface DocumentApiInterface {
    id: number;
    name: string;
    extension: string;
    file_name: string;
    url: string;
}

export enum ApplicationTypeApiEnum {
    'referee_registration' = 'referee_registration',
    'masseur_registration' = 'masseur_registration',
    'paramedic_registration' = 'paramedic_registration',
    'physician_registration' = 'physician_registration',
    'delegate_registration' = 'delegate_registration',
    'person_photo_approval' = 'person_photo_approval',
    'international_player_transfer' = 'international_player_transfer',
    'player_registration' = 'player_registration',
    'amateur_player_transfer' = 'amateur_player_transfer',
    'match_change_request' = 'match_change_request',
    'player_school_transfer_termination' = 'player_school_transfer_termination',
    'professional_player_transfer' = 'professional_player_transfer',
    'professional_player_hosting' = 'professional_player_hosting',
    'professional_player_sub_hosting' = 'professional_player_sub_hosting',
    'player_hosting_termination' = 'player_hosting_termination',
    'player_hosting_extension' = 'player_hosting_extension',
    'player_sub_hosting_termination' = 'player_sub_hosting_termination',
    'player_sub_hosting_extension' = 'player_sub_hosting_extension',
    'player_transfer_to_abroad' = 'player_transfer_to_abroad',
    'member_termination' = 'member_termination',
    'member_roles_termination' = 'member_roles_termination',
    'match_protest' = 'match_protest',
    'account_transaction_contradiction' = 'account_transaction_contradiction',
    'subsidy' = 'subsidy',
    'subsidies_billing' = 'subsidies_billing',
    'personal_fields_name_change_request' = 'personal_fields_name_change_request',
    'personal_fields_more_info_change_request' = 'personal_fields_more_info_change_request',
    'personal_fields_contacts_change_request' = 'personal_fields_contacts_change_request',
}

export enum ApplicationStateApiEnum {
    'work_in_progress' = 'work_in_progress',
    'waiting_for_approval' = 'waiting_for_approval',
    'waiting_for_update' = 'waiting_for_update',
    'approved' = 'approved',
    'declined' = 'declined',
    'cancelled' = 'cancelled',
}

export interface ApplicationBaseEntityReferenceApiInterface {
    id: number;
    name: string;
    type: EntityReferenceApiEnum;
    internal_id: string;
}

export interface ApplicationBaseInterface {
    id: number;
    applicant: ApplicationBaseEntityReferenceApiInterface;
    related_to: ApplicationBaseEntityReferenceApiInterface;
    name: string;
    person_id: number;
    type: ApplicationTypeApiEnum;
    created_at: string;
    updated_at: string;
    discipline: DisciplineEnum | null;
}

export interface ApplicationDetailInterface extends ApplicationBaseInterface {
    state: {
        name: ApplicationStateApiEnum;
    };
    internal_state?: {
        name: ApplicationStateApiEnum;
        reason?: string;
        label: string | null;
        is_returned: boolean;
    };
}

export interface ApplicationListInterface extends ApplicationBaseInterface {
    state: ApplicationStateApiEnum;
}

export interface CompetitionInfoApiInterface {
    id: number;
    identifier: string;
    name: string;
    discipline: DisciplineEnum;
    year: number;
    match_duration_minutes?: number;
    show_video_referee?: boolean;
    category_type?: CompetitionCategoryTypeApiEnum;
    group_type?: CompetitionGroupTypeApiEnum;
    level_type?: number;
    match_record_type: CompetitionMatchRecordTypeApiEnum;
    match_finish_condition_type: CompetitionMatchFinishConditionTypeApiEnum;
}

export interface PersonRoleApiInterface {
    person_id: number;
    role: string;
}

export interface ClubPassportApiInterface {
    id: number;
    internal_id: number;
    name?: string;
    country_code: string;
    category: number;
}

export enum TransferTypeEnum {
    '19_2a' = '19_2a',
    '19_2b' = '19_2b',
    '19_2c' = '19_2c',
    '19_3a' = '19_3a',
    'cat_u12_with_owner_club_approval' = 'cat_u12_with_owner_club_approval',
    'cat_u12_without_owner_club_approval' = 'cat_u12_without_owner_club_approval',
    'cat_u11_with_owner_club_approval' = 'cat_u11_with_owner_club_approval',
    'cat_u11_without_owner_club_approval' = 'cat_u11_without_owner_club_approval',
    'school' = 'school',
    'inactive_player' = 'inactive_player',
    'after_professional_contract_end' = 'after_professional_contract_end',
    'return_to_owner_club' = 'return_to_owner_club',
}

export enum TransferDirectionEnum {
    'domestic' = 'domestic',
    'from_abroad' = 'from_abroad',
    'to_abroad' = 'to_abroad',
}

export enum RegistrationCauseEnum {
    'registration' = 'registration',
    'transfer' = 'transfer',
    'restricted_transfer' = 'restricted_transfer',
    'hosting' = 'hosting',
    'sub_hosting' = 'sub_hosting',
    'reamaterization' = 'reamaterization',
}

export enum RegistrationTypeEnum {
    'first_registration' = 'first_registration',
    'registration_renewal' = 'registration_renewal',
    'registration_change' = 'registration_change',
}

export enum LevelTypeEnum {
    'amateur' = 'amateur',
    'pro' = 'pro',
}

export interface PassportApiInterface {
    club: ClubPassportApiInterface;
    active: boolean;
    registration_type: RegistrationTypeEnum;
    discipline: string;
    level_type?: LevelTypeEnum;
    registration_cause: RegistrationCauseEnum;
    transfer_direction?: TransferDirectionEnum;
    transfer_type: TransferTypeEnum;
    fifa_id?: string;
    date_from: string;
    date_to: string;
    is_temporary: boolean | null;
}

export interface ClubContractApiInterface {
    id: number;
    internal_id: number;
    name: string;
    discipline: DisciplineEnum;
}

export enum ContractTypeApiEnum {
    'standard' = 'standard',
    'league' = 'league',
    'standard_hosting' = 'standard_hosting',
    'league_hosting' = 'league_hosting',
    'standard_sub_hosting' = 'standard_sub_hosting',
    'league_sub_hosting' = 'league_sub_hosting',
}

export interface PersonReferenceApiInterface {
    id: number;
    internal_id: string;
    name: string;
}

export interface RefereeNominationsApiInterface {
    id: number;
    competition_year: number;
    organisation_unit: OrganisationUnitListItemApiInterface;
}

export interface DelegateApiInterface {
    licenses: Record<DisciplineEnum, LicenseApiInterface>;
    next_match_date: string;
    nominations: Record<DisciplineEnum, RefereeNominationsApiInterface>;
    active_license: never;
    archived_at: string | null;
}

export interface RefereeDetailApiInterface {
    licenses: Record<DisciplineEnum, LicenseApiInterface>;
    nominations: Record<DisciplineEnum, RefereeNominationsApiInterface>;
    next_match_date?: string;
    active_license: never;
    archived_at: string | null;
}

export interface CoachDetailApiInterface {
    active_license: LicenseApiInterface;
    next_match_date?: string;
    nominations: never;
    licenses: never;
    archived_at: string | null;
}

export interface ContractListApiInterface {
    id: number;
    is_active: boolean;
    is_professional: boolean;
    club: ClubContractApiInterface;
    discipline: DisciplineEnum;
    type?: ContractTypeApiEnum;
    effective_to: string;
    effective_from: string;
    valid_from: string;
    valid_to: string;
    option_date: string;
    signatureDate: string;
    agent?: AgentApiInterface;
    note?: string;
    signed_date: string;
    inserted_date: string;
    archived_at?: string;
    terminated_date?: string;
    created_at: string;
    updated_at: string;
    has_dispute: boolean;
    person: {
        person_id: number;
        internal_id: string;
        name: string;
        gender: 'male' | 'female';
        birth_year: number;
        citizen_of: string;
    };
}

export interface ContractDetailApiInterface extends ContractListApiInterface {
    previous_contract_id?: number;
    terminated_date?: string;
    has_dispute: boolean;
    is_accepting_previous_contract: boolean;
    updated_by?: { person_id: number; name: string };
    created_by?: { person_id: number; name: string };
}

export interface PersonDetailRolesApiInterface {
    role: RoleApiEnum;
    is_archived: boolean;
}

export interface PersonDetailsApiInterface {
    id: number;
    agent_id: number | null;
    photo?: string;
    first_name: string | null;
    middle_name?: string;
    last_name: string;
    title?: string;
    gender: GenderEnum;
    birth_date?: string;
    national_identifier: string;
    passport?: string;
    birth_country_code?: string;
    birth_city?: string;
    citizen_of?: string;
    second_citizen_of?: string;
    language?: string;
    address: AddressApiInterface;
    contacts: ContactApiInterface[];
    roles: PersonDetailRolesApiInterface[];
    marketing_agreement: boolean;
    targito_agreement: boolean;
    targito_card_issued_successfully: boolean;
}

export interface ClubRegistrationApiInterface {
    id: number;
    name: string;
    date_from: string;
    date_to: string;
}

export interface ValuesStringInterface {
    [key: string]: string;
}

export interface MemberRegistrationApiInterface {
    discipline: DisciplineEnum;
    user?: {
        password: string;
    };
    person: {
        first_name: string | null;
        middle_name?: string;
        last_name: string;
        title?: string;
        email?: string;
        phone: string;
        gender: 'male' | 'female';
        birth_date: string;
        national_identifier: string;
        passport: string;
        birth_city?: string;
        birth_country_code: string;
        citizen_of: string;
        second_citizen_of?: string;
        language: string;
        emails?: string[];
        phones?: string[];
        photo?: string;
        marketing_agreement: boolean;
        targito_agreement: boolean;
    };

    /**
     * Id of club when the person is registered through a club,
     * or null if no club specified.
     */
    register_through_club_id?: number;

    /**
     * Copy address from another user person. Only useful when registering another user person.
     */
    copy_permanent_address?: boolean;
    address: {
        street: string;
        street_number: string;
        city: string;
        postal_code: string;
        country_code: string;
    };
    verification?: {
        email?: string | number;
    };
    /**
     * Name of a lawful representative of a registered member, if registered
     * member is a minor. Only used when current user doesn't have an adult.
     */
    representative?: {
        first_name: string;
        last_name: string;
    };

    /**
     * The representative relation and representative fields are not related
     */
    representative_relation?: RepresentativeRelationEnum;

    payment_url?: string;
}

export interface UploadPhotoApiInterface {
    id: number;
    photo: string;
}

export interface TeamWithSummaryStatsApiInterface extends TeamApiInterface {
    summary_stats_direction: TeamSummaryStatsDirectionEnum;
}

export interface TeamApiInterface {
    id: number;
    name: string;
    small_name: string;
    club: ClubBaseWithDisciplineApiInterface;
}

export enum PlayerIndicesApiEnum {
    released = 'released',
    requested = 'requested',
    contract = 'contract',
    foreign_player = 'foreign_player',
}

export enum IndicesStateApiEnum {
    active = 'active',
    inactive = 'inactive',
}

export enum DisciplineEnum {
    football = 'football',
    futsal = 'futsal',
}

export enum MembershipStatusEnum {
    active = 'active', // green
    inactive = 'inactive', // red
    waiting_for_payment = 'waiting_for_payment', // grey
}

export interface CompetitionRewardsApiInterface {
    name: string;
    amounts: Record<RefereeApiEnum, number>;
}

export interface LicenseApiInterface {
    type: PersonLicenseTypeApiEnum;
    valid_from: string;
    valid_to: string;
}

export interface MembershipApiInterface {
    id: number;
    person_id: number;
    discipline: DisciplineEnum;
    is_valid: boolean;
    status: MembershipStatusEnum;
    year: number;
    valid_from: string;
    valid_to: string;
    amount: number;
    basic_amount: number;
    administration_fee: number;
    created_at: string;
    note: string;
    paid_by: string;
    payment?: {
        paid_at?: string;
    };
    person: {
        person_id: number;
        internal_id: string;
        name: string;
    };
}

export interface MemberDetailApiInterface {
    person_id: number;
    internal_id: string;
    football_state: FootballStateEnum;
    futsal_state: FutsalStateEnum;
    created_at: string;
    updated_at: string;
    note: string;
}

export enum AddressTypeApiEnum {
    'section' = 'section',
    'residence' = 'residence',
    'shipping' = 'shipping',
    'workplace' = 'workplace',
    'temp_residence' = 'temp_residence',
    'foreign_section' = 'foreign_section',
    'foreign_residence' = 'foreign_residence',
}

export interface PersonAddressApiInterface {
    id?: number;
    street: string;
    street_number: string;
    postal_code: string;
    city: string;
    country_code: string;
    type: AddressTypeApiEnum;
    first_line?: string;
}

export interface AddressApiInterface {
    id?: number;
    street: string;
    street_number: string;
    postal_code: string;
    city: string;
    country_code: string;
    first_line?: string;
    created_at?: string;
    updated_at?: string;
}

export enum RepresentativeRelationEnum {
    PARENT = 'parent',
    GUARDIAN = 'guardian',
    CUSTODIAN = 'custodian',
    OTHER = 'other',
}

export enum FootballStateEnum {
    NEW = 'new',
    ACTIVE = 'active',
    ACTIVE_NEXT_YEAR_DEADLINE = 'active_next_year_deadline',
    ACTIVE_NEXT_YEAR_DEADLINE_PROTECTION = 'active_next_year_deadline_protection',
    INACTIVE = 'inactive',
    ARCHIVE = 'archive',
}

export enum FutsalStateEnum {
    NEW = 'new',
    ACTIVE = 'active',
    ACTIVE_NEXT_YEAR_DEADLINE = 'active_next_year_deadline',
    ACTIVE_NEXT_YEAR_DEADLINE_PROTECTION = 'active_next_year_deadline_protection',
    INACTIVE = 'inactive',
    ARCHIVE = 'archive',
}

export interface CompetitionRequestApiInterface {
    competition_team_id?: number;
    day_in_week?: number;
    new_pitch_id?: number;
    offset_hours?: number;
    offset_minutes?: number;
}

export interface ClubCompetitionTeamApiInterface {
    id: number;
    name: string;
    number: number;
    default_points: number;
    default_score: {
        scored: number;
        got: number;
    };
    players_count: number;
    competition: {
        id: number;
        identifier: number;
        name: string;
    };
    pitch: {
        id: number;
        name: string;
    };
    summary_stats_direction: TeamSummaryStatsDirectionEnum;
    actions?: Record<
        ClubCompetitionTeamActionsApiEnum,
        ConfigModuleApiInterface
    >;
}

export enum ClubCompetitionTeamActionsApiEnum {
    'show_competition_team_list_players' = 'show_competition_team_list_players',
}

export interface PitchApiInterface {
    id: number;
    name: string;
    surface?: SurfaceEnum;
}

export enum SurfaceEnum {
    NOT_SET = 'not_set',
    GRASS = 'grass',
    UMT = 'umt',
    OTHER = 'other',
}

export enum TeamSummaryStatsDirectionEnum {
    ASCENDING = 'ascending',
    DESCENDING = 'descending',
}

export enum CompetitionTeamStateApiEnum {
    APPROVED = 'approved',
    REJECTED = 'rejected',
    PENDING = 'pending',
}

export interface CompetitionGlobalChangeRequestInterface {
    id: number;
    number: number;
    competition: CompetitionGlobalChangeRequestCompetitionInterface;
    competition_team: TeamApiInterface;
    day_in_week: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
    offset_hours: number;
    offset_minutes: number;
    old_pitch: PitchApiInterface;
    new_pitch: PitchApiInterface;
    state: CompetitionTeamStateApiEnum;
    approved_datetime: boolean;
    approved_pitch: boolean;
    actions?: Record<
        CompetitionGlobalChangeRequestActionsEnum,
        ConfigModuleApiInterface
    >;
}

export enum CompetitionGlobalChangeRequestActionsEnum {
    global_change_request_approve_all = 'global_change_request_approve_all',
    global_change_request_approve_pitch = 'global_change_request_approve_pitch',
    global_change_request_approve_datetime = 'global_change_request_approve_datetime',
    global_change_request_decline = 'global_change_request_decline',
    global_change_request_delete = 'global_change_request_delete',
}

export interface CompetitionGlobalChangeRequestCompetitionInterface {
    id: number;
}

export interface CompetitionDatesInterface {
    spring: CompetitionDateInterface[];
    autumn: CompetitionDateInterface[];
}

export interface CompetitionDateInterface {
    season: SeasonApiEnum;
    number: number;
    number_berger: number;
    datetime: string;
    actions?: Record<CompetitionDateActionsEnum, ConfigModuleApiInterface>;
}

export enum CompetitionDateActionsEnum {
    'delete_competition_date' = 'delete_competition_date',
}

export interface CompetitionLfaBasicInterface {
    id: number;
}

export interface CompetitionBaseApiInterface {
    id: number;
    discipline: DisciplineEnum;
    name: string;
    year: number;
    category_type: CompetitionCategoryTypeApiEnum;
    level_type: number;
    group_type: CompetitionGroupTypeApiEnum;
    code: string;
    identifier: string;
    kind: string;
    organisation_unit: OrganisationUnitApiInterface;
}

export interface CompetitionTeamsBaseApiInterface {
    approved: number;
    pending: number;
    rejected: number;
}

export interface CompetitionListApiInterface
    extends CompetitionBaseApiInterface {
    settings: CompetitionSettingsApiInterface;
    is_public_autumn: boolean;
    is_public_spring: boolean;
}

export interface CompetitionApiInterface extends CompetitionBaseApiInterface {
    settings: CompetitionSettingsApiInterface;
    teams: CompetitionTeamsBaseApiInterface;
}

export interface CompetitionWithWarningsInterface {
    warnings: CompetitionWarningApiInterface[];
}

export interface CompetitionTeamListErrorInterface {
    current: number;
    required: number;
}

export interface CompetitionTeamDuplicateListErrorInterface {
    teams: CompetitionTeamDuplicateErrorInterface[];
    duplicate_number: number;
}

export interface CompetitionTeamDuplicateErrorInterface {
    [key: string]: string;
}

export interface CompetitionDatesDuplicateListErrorInterface {
    duplicate_date_numbers: number[];
    duplicate_number_berger: number;
}

export interface CompetitionWarningApiInterface {
    error_key: CompetitionWarningErrorKeyApiEnum;
    data:
        | CompetitionTeamListErrorInterface
        | CompetitionTeamDuplicateListErrorInterface[]
        | CompetitionDatesDuplicateListErrorInterface[];
}

export enum CompetitionWarningErrorKeyApiEnum {
    'competition_teams_missing' = 'competition_teams_missing',
    'competition_dates_missing' = 'competition_dates_missing',
    'competition_dates_datetime_not_set' = 'competition_dates_datetime_not_set',
    'competition_teams_duplicate_number' = 'competition_teams_duplicate_number',
    'competition_dates_duplicate_number_berger' = 'competition_dates_duplicate_number_berger',
}

export interface CompetitionSettingsApiInterface {
    draw_type: string;
    match_record_type: CompetitionMatchRecordTypeApiEnum;
    match_finish_condition_type: CompetitionMatchFinishConditionTypeApiEnum;
    show_video_referee: boolean;
    enable_change_requests: boolean;
    teams_count: number;
    max_rounds: number;
    match_duration_minutes: number;
    max_matches_in_round: number;
    min_yellow_cards_for_investigation: number;
    lfa_basic_competition: CompetitionLfaBasicInterface;
    lfa_competition_type: CompetitionLfaTypeApiEnum;
    rewards: CompetitionSettingsRewardsApiInterface;
    is_public_autumn: boolean;
    is_public_spring: boolean;
    disable_competition_rewards_to_account: boolean;
    competition_start_fee?: number;
}

export interface CompetitionSettingsRewardsApiInterface {
    id: number;
    name: string;
    amounts: AmountInterface[];
}

export interface CompetitionDateCreateApiInterface {
    dates_count: number;
    season: SeasonApiEnum;
}

export interface CompetitionCopyDatesApiInterface {
    competition_id: number;
}

export interface CompetitionDateEditApiInterface {
    [key: string]: string | number;
}

export interface CompetitionTeamEditApiInterface {
    name?: string;
    number?: number;
    default_score_scored?: number;
    default_score_got?: number;
    default_points?: number;
    summary_stats_direction?: TeamSummaryStatsDirectionEnum;
}

export interface CompetitionTeamAssociateApiInterface {
    associated_club_id: string;
}

export interface CompetitionTeamConfirmListApiInterface {
    season: SeasonApiEnum;
}

export interface CompetitionCopyTeamsFromCompetitionApiInterface {
    from_competition_id: number;
}

export interface CompetitionCopyTeamTournamentApiInterface {
    source_competition_a: CompetitionCopyTeamTournamentSourceApiInterface;
    source_competition_b: CompetitionCopyTeamTournamentSourceApiInterface;
}

export interface CompetitionCopyTeamTournamentSourceApiInterface {
    competition_id: number;
    summary_stats_type: TeamSummaryStatsDirectionEnum;
}

export interface CompetitionGenerateDatesApiInterface {
    seasons: CompetitionGenerateDatesSeasonsApiInterface;
    lighting_type: CompetitionLightningTypeApiEnum;
    time_type: CompetitionTimeTypeApiEnum;
    week_day: number;
    time?: string;
}

export interface CompetitionGenerateDatesSeasonsApiInterface {
    autumn?: CompetitionGenerateDateSeasonApiInterface;
    spring?: CompetitionGenerateDateSeasonApiInterface;
}

export interface CompetitionGenerateDateSeasonApiInterface {
    start_date: string;
}

export interface CompetitionGlobalChangeRequestDataApiInterface {
    approved_datetime?: boolean;
    approved_pitch?: boolean;
}

export interface CompetitionGenerateMatchesApiInterface {
    generate_type: CompetitionGenerateTypeApiEnum;
    season_types: SeasonApiEnum[];
    definition_table?: CompetitionGenerateManualSeasonsApiInterface;
}

export interface CompetitionGenerateMatchesResApiInterface {
    autumn?: CompetitionGenerateMatchesResRoundApiInterface;
    spring?: CompetitionGenerateMatchesResRoundApiInterface;
}

export interface CompetitionGenerateMatchesResRoundApiInterface {
    rounds: CompetitionGenerateMatchesResDataApiInterface[];
}

export interface CompetitionGenerateMatchesResDataApiInterface {
    number: number;
    number_berger: number;
    playing_at: string;
    matches: CompetitionGenerateMatchesResRoundMatchDataApiInterface[];
}

export interface CompetitionGenerateMatchesResRoundMatchDataApiInterface {
    number: number;
    playing_at: string;
    guest_team: CompetitionGenerateMatchesResRoundMatchTeamDataApiInterface;
    home_team: CompetitionGenerateMatchesResRoundMatchTeamDataApiInterface;
}

export interface CompetitionGenerateMatchesResRoundMatchTeamDataApiInterface {
    internal_id: string;
    number: number;
    name: string;
}

export interface CompetitionGenerateManualSeasonsApiInterface {
    autumn?: [[number, number][]];
    spring?: [[number, number][]];
}

export interface CompetitionUpdateApiInterface {
    name: string;
    draw_type?: string;
    show_video_referee: boolean;
    enable_change_requests: boolean;
    teams_count?: number;
    max_rounds?: number;
    match_duration_minutes?: number;
    max_matches_in_round?: number;
    min_yellow_cards_for_investigation: number;
    lfa_competition_type?: CompetitionLfaTypeApiEnum;
    lfa_basic_competition_id?: number;
    competition_reward_id: number;
    is_public_autumn?: boolean;
    is_public_spring?: boolean;
    disable_competition_rewards_to_account?: boolean;
    competition_start_fee?: number;
}

export interface CompetitionSignUpClubApiInterface {
    club_id: number;
    pitch_id?: number;
    number?: number;
    pitch_name?: string;
    competition_team_name: string;
}

export interface CompetitionDispatchApiInterface {
    name: string;
    year: number;
    discipline: DisciplineEnum;
    organisation_unit_id: number;
    category_type: CompetitionCategoryTypeApiEnum;
    level_type: number;
    group_type: CompetitionGroupTypeApiEnum;
    draw_type: string;
    match_record_type: CompetitionMatchRecordTypeApiEnum;
    match_finish_condition_type: CompetitionMatchFinishConditionTypeApiEnum;
    show_video_referee: boolean;
    enable_change_requests: boolean;
    teams_count: number;
    max_rounds: number;
    match_duration_minutes: number;
    max_matches_in_round: number;
    min_yellow_cards_for_investigation: number;
    lfa_competition_type: CompetitionLfaTypeApiEnum;
    lfa_basic_competition_id: number;
    competition_reward_id: number;
}

export interface AmountInterface {
    role: string;
    amount: number;
}

export enum CompetitionCategoryTypeApiEnum {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    F = 'F',
    G = 'G',
    H = 'H',
    I = 'I',
    J = 'J',
    K = 'K',
    L = 'L',
    M = 'M',
    N = 'N',
    X = 'X',
    Y = 'Y',
    Z = 'Z',
    C1 = 'Č',
}

export enum CompetitionGroupTypeApiEnum {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    F = 'F',
    G = 'G',
    H = 'H',
    I = 'I',
    J = 'J',
    K = 'K',
    L = 'L',
    M = 'M',
    N = 'N',
    O = 'O',
    P = 'P',
    Q = 'Q',
    R = 'R',
    S = 'S',
    T = 'T',
    U = 'U',
    V = 'V',
    W = 'W',
    X = 'X',
    Y = 'Y',
    Z = 'Z',
}

export enum CompetitionMatchRecordTypeApiEnum {
    standard = 'standard',
    free = 'free',
}

export enum CompetitionMatchFinishConditionTypeApiEnum {
    standard = 'standard',
    penalty_kicks = 'penalty_kicks',
}

export enum CompetitionLfaTypeApiEnum {
    basic = 'basic',
    play_off = 'play_off',
    for_rescue = 'for_rescue',
    for_title = 'for_title',
}

export const CompetitionLevelType = [1, 2, 3, 4];
export const CompetitionDrawType = [0, 1, 2, 3, 4, 5, 6, 7, 8, 'C', 'N', 'T'];

export interface CompetitionStatsPlayersApiInterface {
    rank: number;
    person: PersonBasicDataApiInterface;
    stats: CompetitionStatsPlayersStatsApiInterface;
    competition_team: TeamWithSummaryStatsApiInterface;
}

export interface CompetitionStatsPlayersStatsApiInterface {
    punishments_count: CompetitionStatsPlayersPunihsmentCountApiInterface;
    goals_count: number;
    played_minutes: number;
    matches_count: number;
}

export interface CompetitionStatsPlayersPunihsmentCountApiInterface {
    yellow: number;
    red: number;
}

export interface CompetitionStatsTeamsApiInterface {
    rank: number;
    competition_team: TeamWithSummaryStatsApiInterface;
    stats: CompetitionStatsTeamsStatsApiInterface;
}

export interface CompetitionStatsTeamsStatsApiInterface {
    points: number;
    matches_count: number;
    wins_count: number;
    loss_count: number;
    draw_count: number;
    score_scored: number;
    score_got: number;
}

export enum CompetitionStatsTeamTypeApiEnum {
    total = 'total',
    home = 'home',
    guest = 'guest',
    ascending = 'ascending',
    descending = 'descending',
}

export interface MessageApiInterface {
    id: string;
    type: string;
    sender?: EntityReferenceWithTypeApiInterface;
    subject: string;
    message?: string;
    read_at: string;
    created_at: string;
}

export enum UnitApiEnum {
    'person' = 'person',
    'organisation' = 'organisation',
    'club' = 'club',
}

export enum CompetitionTimeTypeApiEnum {
    OFFICE = 'office_time',
    BEFORE = 'before_office_time',
    AFTER = 'after_office_time',
    EXACT = 'exact_time',
}

export enum CompetitionLightningTypeApiEnum {
    DAYLIGHT = 'daylight',
    ARTIFICIAL = 'artificial',
}

export interface OrganisationUnitApiInterface {
    id: number;
    name: string;
}

export interface OrganisationUnitUpdateApiInterface {
    name: string;
    web?: string | null;
    roles: OrganisationUnitRoleApiEnum[];
}

export interface OrganisationUnitRoleUpdateApiInterface {
    email: string;
}

export interface OrganisationUnitAddPersonApiInterface {
    person_id: number;
    role: OrganisationUnitRoleApiEnum;
    position: OrganisationUnitPersonPositionApiEnum;
    is_admin?: boolean;
}

export interface OrganisationUnitChangeLegalPersonApiInterface {
    legal_person_id: number;
}

export interface OrganisationUnitSearchApiInterface
    extends OrganisationUnitApiInterface {
    appeal_commission: boolean;
    audit_commission: boolean;
    code: string;
    delegate_commission: boolean;
    disciplinary_commission: boolean;
    discipline: DisciplineEnum;
    ethical_commission: boolean;
    executive_committee: boolean;
    legal_person: LegalPersonBaseApiInterface;
    organisation_unit_admin: boolean;
    parent: OrganisationUnitApiInterface;
    referee_commission: boolean;
    sport_technical_commission: boolean;
    type: OrganisationUnitTypeApiEnum;
}

export interface OrganisationUnitDetailApiInterface
    extends OrganisationUnitApiInterface {
    parent_id: number;
    code: string;
    name: string;
    web: string | null;
    legal_person_id: number;
    discipline: DisciplineEnum;
    type: OrganisationUnitTypeApiEnum;
    roles: OrganisationUnitRoleApiEnum[];
    created_at: string;
    updated_at: string;
}

export interface OrganisationUnitPersonApiInterface {
    is_admin: boolean;
    role: OrganisationUnitRoleApiEnum;
    position: OrganisationUnitPersonPositionApiEnum;
    email: string;
    phone: string;
    person: PersonBasicDataApiInterface;
    created_at: string;
    updated_at: string;
}

export interface OrganisationUnitRoleDetailApiInterface {
    id: number;
    email: string;
    admin_position: OrganisationUnitPersonPositionApiEnum;
    admin: PersonBasicDataApiInterface;
    created_at: string;
    updated_at: string;
    deleted_at: string;
}

export enum OrganisationUnitTypeApiEnum {
    control_com = 'control_com',
    kfs = 'kfs',
    ofs = 'ofs',
}

export interface LegalPersonBaseApiInterface {
    id: number;
    name: string;
    internal_id: string | null;
}

export interface LegalPersonApiInterface extends LegalPersonBaseApiInterface {
    address: AddressApiInterface;
    club_id: number;
    ico: string;
    vat: string;
    role: LegalPersonRoleApiEnum;
    created_at: string;
    updated_at: string;
}

export enum LegalPersonRoleApiEnum {
    club = 'club',
    organisation_unit = 'organisation_unit',
    other = 'other',
}

export interface LegalPersonDetailApiInterface {
    id: number;
    name: string;
    agent_id: number | null;
    internal_id: string | null;
    role: LegalPersonRoleApiEnum;
    ico: string;
    vat: string;
    bank_account: BankApiInterface;
    double_entry_accounting: boolean;
    contacts: ContactApiInterface[];
    address: AddressApiInterface;
    shipping: ShippingApiInterface;
    created_at: string;
    updated_at: string;
    archived_at: string;
    is_vat_payer: boolean | null;
}

export interface BankApiInterface {
    id: number;
    country_code: string;
    code: string;
    number: string;
    bank_code: string;
    iban: string;
    swift: string;
    beneficiary: string;
    created_at: string;
    updated_at: string;
}

export const MESSAGE_APPLICATION_SENT = 'application_sent';

export interface BillingFieldsApiInterface {
    name: string;
    ico?: string;
    vat?: string;
    is_vat_payer?: boolean;
    double_entry_accounting?: boolean;
    bank_account?: BankApiInterface;
    address: AddressApiInterface;
}

export interface ShippingApiInterface {
    type: DeliveryType;
    email: string;
    address: AddressApiInterface;
}

export interface ClubBaseApiInterface {
    id: number;
    internal_id: string;
    name: string;
    logo: PhotoLogoApiInterface | null;
    legal_person_id: number | null;
}

export interface PhotoLogoApiInterface {
    30: string;
    60: string;
    90: string;
    180: string;
    270: string;
    540: string;
    full: string;
}

export interface ClubBaseWithDisciplineApiInterface
    extends ClubBaseApiInterface {
    discipline: DisciplineEnum;
}

export interface OrganizationUnitWithRolesApiInterface {
    id: number;
    code: string;
    name: string;
    role: OrganisationUnitRoleApiEnum;
    is_admin: boolean;
}

export interface OrganizationUnitRolesApiInterface {
    role: OrganisationUnitRoleApiEnum;
    notification_email: string;
    created_at: string;
    updated_at: string;
}

export enum OrganisationUnitRoleApiEnum {
    sport_technical_commission = 'sport_technical_commission',
    disciplinary_commission = 'disciplinary_commission',
    referee_commission = 'referee_commission',
    delegate_commission = 'delegate_commission',
    audit_commission = 'audit_commission',
    ethical_commission = 'ethical_commission',
    appeal_commission = 'appeal_commission',
    executive_committee = 'executive_committee',
    organisation_unit_admin = 'organisation_unit_admin',
    coach_commission = 'coach_commission',
    coach_department = 'coach_department',
    revision_commission = 'revision_commission',
    economy_commission = 'economy_commission',
    women_commission = 'women_commission',
    youth_commission = 'youth_commission',
    grassroots_youth_trainer_commission = 'grassroots_youth_trainer_commission',
}

export enum OrganisationUnitPersonPositionApiEnum {
    chairman = 'chairman',
    head_secretary = 'head_secretary',
    other = 'other',
    secretary = 'secretary',
}

export enum CompetitionGenerateTypeApiEnum {
    MANUAL = 'manual',
    BERGER = 'berger',
}

export enum SeasonApiEnum {
    BOTH = 'both',
    AUTUMN = 'autumn',
    SPRING = 'spring',
}

export interface FifaPlayerDuplicateInterface {
    id: number;
    model_id: number;
    last_name: string;
    first_name: string | null;
    birth_country: string;
    birth_city: string;
    birth_date: string;
    internal_id: string;
    national_identifier: string;
    num_of_duplicates: number;
    club: ClubContractApiInterface;
    registered_date: string;
    created_at: string;
    updated_at: string;
}

export enum EntityReferenceApiEnum {
    'person' = 'person',
    'referee' = 'referee',
    'delegate' = 'delegate',
    'player' = 'player',
    'competition' = 'competition',
    'club_competition' = 'club_competition',
    'match' = 'match',
    'organisation_unit' = 'organisation_unit',
    'club' = 'club',
    'legal_person' = 'legal_person',
    'agent' = 'agent',
    'proceeding' = 'proceeding',
    'application' = 'application',
    'subsidy' = 'subsidy',
    'users_groups' = 'users_groups',
}

export interface PersonEntityReferenceApiInterface {
    person_id: number;
    internal_id: string;
    name: string;
    photo?: PhotoLogoApiInterface;
}

export interface ClubReferenceApiInterface {
    id: number;
    internal_id: string;
    name: string;
    small_name?: string;
}

export interface ClubCompetitionReferenceApiInterface {
    club_id: number;
    competition_id: number;
    team_id: number;
    name: string;
}

export interface CompetitionReferenceApiInterface {
    id: number;
    identifier: string;
    name: string;
}

export interface OrganisationUnitReferenceApiInterface {
    id: number;
    name: string;
}

export interface MatchReferenceApiInterface {
    id: number;
    identifier?: string;
    name?: string;
    small_name?: string;
}

export interface LegalPersonReferenceApiInterface {
    id: number;
    internal_id: string;
    name: string;
}

export interface SubsidyReference {
    id: number;
    name: string;
    type: 'subsidy';
    internal_id: string;
}

export interface AgentReference {
    id: number;
    identifier: string;
    person: PersonEntityReferenceApiInterface;
    legal_person: LegalPersonReferenceApiInterface;
}

export interface UserReference {
    id: number;
    person: PersonBasicDataApiInterface;
}

export interface ProceedingEntityReferenceApiInterface {
    id: number;
    identifier: string;
    type: ProceedingTypeApiEnum;
}

export interface ApplicationEntityReferenceApiInterface {
    id: number;
    state: ApplicationStateApiEnum;
    current_state: ApplicationStateApiEnum;
    current_state_label: ApplicationStateApiEnum;
    type: ApplicationTypeApiEnum;
}

export interface UsersGroupReference {
    key: string;
}

export type EntityReferenceApiType =
    | PersonEntityReferenceApiInterface
    | OrganisationUnitReferenceApiInterface
    | MatchReferenceApiInterface
    | CompetitionReferenceApiInterface
    | ClubReferenceApiInterface
    | ClubCompetitionReferenceApiInterface
    | ProceedingEntityReferenceApiInterface
    | ApplicationEntityReferenceApiInterface
    | SubsidyReference
    | AgentReference
    | UsersGroupReference;

export interface EntityReferenceWithTypeApiInterface {
    type: EntityReferenceApiEnum;
    reference: EntityReferenceApiType;
}

export enum PaidStateApiEnum {
    no_document = 'no_document',
    not_paid = 'not_paid',
    new_not_paid = 'new_not_paid',
    not_paid_in_due_date = 'not_paid_in_due_date',
    under_paid = 'under_paid',
    paid = 'paid',
    over_paid = 'over_paid',
}

export interface BulkMembershipSearchApiInterface {
    id: number;
    year: number;
    variable_symbol: string;
    members_count: number;
    amount: number;
    paid_state: MembershipPaymentStateApiEnum;
    paid_at: string;
    club: ClubBaseWithDisciplineApiInterface;
    created_at: string;
    updated_at: string;
}

export interface BulkMembershipDetailApiInterface
    extends Omit<BulkMembershipSearchApiInterface, 'club'> {
    sap?: {
        state: number;
        last_successful_sync_at: string;
        last_unsuccessful_sync_at: string;
        paid_amount: number;
    };
    payment?: {
        paid_at: string;
        sap_synced_at: string;
    };
    pending_payment: boolean;
    created_by?: {
        person_id: number;
        internal_id: string;
        name: string;
    };
}

export interface BulkMembershipMemberApiInterface {
    person: {
        person_id: number;
        internal_id: string;
        name: string;
    };
    club_roles: ClubPersonRolesApiEnum[];
    amount: number;
    created_at: string;
    updated_at: string;
}

export enum MembershipPaymentStateApiEnum {
    NOT_PAID = 0,
    UNDERPAID = 1,
    PAID = 2,
    OVERPAID = 3,
}

export interface OrganisationUnitListItemApiInterface {
    id: number;
    parent_id: number;
    code: string;
    name: string;
}

export interface UserOrganisationUnitApiInterface {
    id: number;
    code: string;
    name: string;
    role: OrganisationUnitRoleApiEnum;
    is_admin: boolean;
}
export enum DecisionOriginatorTypeApiEnum {
    organisation_unit = 'organisation_unit',
    group = 'group',
    person = 'person',
}

export enum DecisionRecipientTypeApiEnum {
    club = 'club',
    person = 'person',
    role = 'role',
}

export enum DecisionRecipientRoleEnum {
    clubs = 1,
    players = 2,
    referees = 3,
    agents = 4,
    delegates = 5,
    functionaries = 6,
    physicians = 7,
    members = 8,
}

export interface JurisdictionApiInterface {
    id: number;
    parent_id: number;
    name: string;
    tree_name: string;
    organisation_unit_id: number;
}

export enum FileTypeApiEnum {
    'text/csv' = 'text/csv',
    'application/pdf' = 'application/pdf',
    'application/vnd.ms-excel' = 'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint' = 'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation' = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/msword' = 'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document' = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}

export enum NominationStateApiEnum {
    new = 'new',
    finished = 'finished',
}

export enum NominationRoleApiEnum {
    referee = 'referee',
    delegate = 'delegate',
}

export interface NominationApiInterface {
    id: number;
    organisation_unit: OrganisationUnitListItemApiInterface;
    competition_year: number;
    discipline: DisciplineEnum;
    role: NominationRoleApiEnum;
    state: NominationStateApiEnum;
    persons_count: number;
    created_at: string;
    updated_at: string;
}

export interface NominationPersonApiInterface {
    person: PersonBasicDataApiInterface;
    license: LicenseApiInterface;
    created_at: string;
    updated_at: string;
}

export interface NominationCreatePayloadApiInterface {
    organisation_unit_id: number;
    discipline: DisciplineEnum;
    role: NominationRoleApiEnum;
    competition_year: number;
}

export interface GroupUserApiInterface {
    user_id: number;
    group_key: UserRoleApiEnum;
    discipline: DisciplineEnum;
    is_admin: true;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    actions: {
        update_user_in_group: ConfigModuleApiInterface;
        delete_user_in_group: ConfigModuleApiInterface;
    };
    person: PersonBasicDataApiInterface;
}

export interface UpdateGroupUserPayloadApiInterface {
    is_admin: boolean;
}

export enum ContractStateApiEnum {
    'new' = 'new',
    'waiting_for_approval' = 'waiting_for_approval',
    'approved' = 'approved',
    'invalidated' = 'invalidated',
    'rejected' = 'rejected',
    'returned_to_creation' = 'returned_to_creation',
    'returned_to_invalidation' = 'returned_to_invalidation',
    'waiting_for_invalidation' = 'waiting_for_invalidation',
}

export interface ContractApiInterface {
    id: number;
    player_person: PersonBasicDataApiInterface;
    number: string;
    state: ContractStateApiEnum;
    reward_percentage: number | null;
    reward_amount: number | null;
    is_exclusive: boolean;
    is_active: boolean;
    valid_from: string | null;
    valid_to: string | null;
    effective_from: string | null;
    effective_to: string | null;
    note: string | null;
    inserted_date: string | null;
    signed_date: string | null;
    created_at?: string;
    updated_at?: string;

    rejection_reason?: string;
    documents: DocumentApiInterface[];
}

export interface AgentApiInterface {
    id: number;
    identifier: string;
    person: PersonBasicDataApiInterface;
    legal_person: LegalPersonBaseApiInterface;
    has_active_insurance: boolean;
    created_by_person: PersonBasicDataApiInterface;
    archived_at: string | null;
}

export interface InsuranceApiInterface {
    id: number;
    number: string;
    amount: number | null;
    insurance_company_name: string;
    is_active: boolean;
    note: string | null;
    valid_from: string | null;
    valid_to: string | null;
    effective_from: string | null;
    effective_to: string | null;
    created_at: string;
    updated_at: string;
    documents: DocumentApiInterface[];
}

export interface InsuranceDate {
    id: number;
    agent_insurance_id: number;
    documents: DocumentApiInterface[];
    valid_from: string;
    valid_to: string;
    created_at: string;
    updated_at: string;
}

export interface ContractPayloadApiInterface {
    effective_from: string;
    effective_to: string;
    is_exclusive: boolean;
    note: string;
    number: string;
    person_id: number;
    reward_amount: number;
    reward_percentage: number;
    valid_from: string;
    valid_to: string;
}

export type ContractEditPayloadApiInterface = Omit<
    ContractPayloadApiInterface,
    'person_id'
>;

export interface InsurancePayloadApiInterface {
    amount: number;
    insurance_company_name: string;
    effective_from: string;
    effective_to: string;
    note: string;
    number: string;
    valid_from: string;
    valid_to: string;
}

export interface InsuranceDatePayloadApiInterface {
    valid_from: string;
    valid_to: string;
}

export interface RegionApiInterface {
    code: string;
    name: string;
    shortcut: string;
}
