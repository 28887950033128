import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Person_PageMenu_ApiEnum } from './PersonDetailConfigApiInterface';

export enum Config_PersonBillingFields_ActionsMenu_ApiEnum {
    'archive' = 'archive',
}

export enum Config_PersonBillingFields_Modules_ApiEnum {
    'indications' = 'indications',
    'overview' = 'overview',
    'fields' = 'fields',
}

export enum Config_PersonBillingFields_ActionsOther_ApiEnum {
    'upload_photo' = 'upload_photo',
}

export enum Config_PersonBillingFields_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_PersonBillingFields_ApiInterface
    extends ConfigDataInterface<
        Config_PersonBillingFields_Modules_ApiEnum,
        Config_PersonBillingFields_ActionsMenu_ApiEnum,
        Config_PersonBillingFields_ActionsOther_ApiEnum,
        Config_Person_PageMenu_ApiEnum,
        Config_PersonBillingFields_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
