import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Match_PageMenu_ApiEnum } from './MatchDetailConfigApiInterface';

export enum Config_MatchRefereeReport_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_MatchRefereeReport_Modules_ApiEnum {
    'indications' = 'indications',
    'overview' = 'overview',
    'reports' = 'reports',
    'warning' = 'warning',
}

export enum Config_MatchRefereeReport_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_MatchRefereeReport_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_MatchRefereeReport_ApiInterface
    extends ConfigDataInterface<
        Config_MatchRefereeReport_Modules_ApiEnum,
        Config_MatchRefereeReport_ActionsMenu_ApiEnum,
        Config_MatchRefereeReport_ActionsOther_ApiEnum,
        Config_Match_PageMenu_ApiEnum,
        Config_MatchRefereeReport_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
