import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_LegalPersonSearch_ActionsMenu_ApiEnum {
    'create_legal_person' = 'create_legal_person',
}

export enum Config_LegalPersonSearch_Modules_ApiEnum {
    'results' = 'results',
}

export enum Config_LegalPersonSearch_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_LegalPersonSearch_PageMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_LegalPersonSearch_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_LegalPersonSearch_ApiInterface
    extends ConfigDataInterface<
        Config_LegalPersonSearch_Modules_ApiEnum,
        Config_LegalPersonSearch_ActionsMenu_ApiEnum,
        Config_LegalPersonSearch_ActionsOther_ApiEnum,
        Config_LegalPersonSearch_PageMenu_ApiEnum,
        Config_LegalPersonSearch_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
