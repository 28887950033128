import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_AccountTransactionsTemplates_ActionsMenu_ApiEnum {
    'create_transaction_template' = 'create_transaction_template',
}

export enum Config_AccountTransactionsTemplates_Modules_ApiEnum {
    'results' = 'results',
}

export enum Config_AccountTransactionsTemplates_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_AccountTransactionsTemplates_PageMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_AccountTransactionsTemplates_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_AccountTransactionsTemplates_ApiInterface
    extends ConfigDataInterface<
        Config_AccountTransactionsTemplates_Modules_ApiEnum,
        Config_AccountTransactionsTemplates_ActionsMenu_ApiEnum,
        Config_AccountTransactionsTemplates_ActionsOther_ApiEnum,
        Config_AccountTransactionsTemplates_PageMenu_ApiEnum,
        Config_AccountTransactionsTemplates_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
