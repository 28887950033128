import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_LegalPerson_PageMenu_ApiEnum } from './LegalPersonDetailConfigApiInterface';
import { Config_Person_PageMenu_ApiEnum } from './PersonDetailConfigApiInterface';

export enum Config_AgentsContractDetail_ActionsMenu_ApiEnum {
    'update_agent_contract' = 'update_agent_contract',
    'agent_contract_mark_as_waiting_for_approval' = 'agent_contract_mark_as_waiting_for_approval',
    'agent_contract_mark_as_returned_to_creation' = 'agent_contract_mark_as_returned_to_creation',
    'agent_contract_mark_as_approved' = 'agent_contract_mark_as_approved',
    'agent_contract_mark_as_rejected' = 'agent_contract_mark_as_rejected',
    'agent_contract_mark_as_waiting_for_invalidation' = 'agent_contract_mark_as_waiting_for_invalidation',
    'agent_contract_mark_as_returned_to_invalidation' = 'agent_contract_mark_as_returned_to_invalidation',
    'agent_contract_mark_as_invalidated' = 'agent_contract_mark_as_invalidated',
}

export enum Config_AgentsContractDetail_Modules_ApiEnum {
    'overview' = 'overview',
    'indications' = 'indications',
    'data' = 'data',
    'documents' = 'documents',
}

export enum Config_AgentsContractDetail_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_AgentsContractDetail_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_AgentsContractDetail_ApiInterface
    extends ConfigDataInterface<
        Config_AgentsContractDetail_Modules_ApiEnum,
        Config_AgentsContractDetail_ActionsMenu_ApiEnum,
        Config_AgentsContractDetail_ActionsOther_ApiEnum,
        Config_LegalPerson_PageMenu_ApiEnum | Config_Person_PageMenu_ApiEnum,
        Config_AgentsContractDetail_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
