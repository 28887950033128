import { UserReference } from '../ApiInterface';

export enum EventTypeEnum {
    CREATED = 'created',
    UPDATED = 'updated',
    DELETED = 'deleted',
}

export interface AuditLogApiInterface {
    id: string;
    source_model_type: string;
    source_model_label: string;
    source_model_id: number;
    column_name: string | null;
    column_label: string | null;
    old_value: string | null;
    new_value: string | null;
    note: string | null;
    created_at: string;
    created_by_user: UserReference | null;
    event_type: EventTypeEnum;
}

export interface GlobalAuditLogApiInterface extends AuditLogApiInterface {
    owner_model: any;
}
