import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Competition_PageMenu_ApiEnum } from './CompetitionDetailConfigApiInterface';

export enum Config_CompetitionTeams_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_CompetitionTeams_Modules_ApiEnum {
    'indications' = 'indications',
    'overview' = 'overview',
    'teams' = 'teams',
    'warning' = 'warning',
}

export enum Config_CompetitionTeams_ActionsOther_ApiEnum {
    'create_competition_team' = 'create_competition_team',
    'copy_teams_from_competition' = 'copy_teams_from_competition',
    'copy_tournament' = 'copy_tournament',
    'edit_competition_team' = 'edit_competition_team',
    'create_competition_start_fee_transactions_bulk' = 'create_competition_start_fee_transactions_bulk',
}

export enum Config_CompetitionTeams_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_CompetitionTeams_ApiInterface
    extends ConfigDataInterface<
        Config_CompetitionTeams_Modules_ApiEnum,
        Config_CompetitionTeams_ActionsMenu_ApiEnum,
        Config_CompetitionTeams_ActionsOther_ApiEnum,
        Config_Competition_PageMenu_ApiEnum,
        Config_CompetitionTeams_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
