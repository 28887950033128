import {
    ApplicationEntityReferenceApiInterface,
    DisciplineEnum,
    DocumentApiInterface,
    LegalPersonApiInterface,
    LegalPersonBaseApiInterface,
} from '../ApiInterface';
import { ConfigModuleApiInterface } from '../config/ConfigApiInterface';

export interface SubsidyListApiInterface {
    id: number;
    name: string;
    group: string;
    is_delegated_to_organisation_unit: boolean;
    amount: number;
    competition_year: number;
    is_for_clubs: boolean;
    is_for_organisation_units: boolean;
    start_at: string;
    end_at: string;
    created_at: string;
    updated_at: string;
}

export interface SubsidyCreateApiInterface {
    type: SubsidyTypeApiEnum;
    discipline: DisciplineEnum;
    name: string;
    description: string;
    is_for_clubs: boolean;
    is_for_organisation_units: boolean;
    start_at: string;
    end_at: string;
    publish_at: string;
    competition_year: number;
    contract_text: string;
    is_delegated_to_organisation_unit: boolean;
}

export interface SubsidyAddLegalPersonApiInterface {
    amount: number;
}

export interface SubsidyAddLegalPersonExcelApiInterface {
    file: File;
    id_column: string;
    amount_column: string;
    start_from_row: number;
    type: SubsidyAddLegalPersonExcelTypeApiEnum;
}

export enum SubsidyAddLegalPersonExcelTypeApiEnum {
    'member' = 'member',
    'id' = 'id',
}

export enum SubsidyTypeApiEnum {
    'grant' = 'grant',
    'billing' = 'billing',
}

export interface SubsidyApiInterface {
    id: number;
    name: string;
    group: string;
    type: SubsidyTypeApiEnum;
    description: string;
    contract_text: string;
    is_for_clubs: boolean;
    is_for_organisation_units: boolean;
    publish_at: string;
    start_at: string;
    end_at: string;
    competition_year: number;
    created_at: string;
    updated_at: string;
    discipline: DisciplineEnum;
    documents: SubsidyDocumentApiInterface[];
    is_delegated_to_organisation_unit: boolean;
}

export interface SubsidyLegalPersonApiInterface {
    legal_person_id: number;
    amount: number;
}

export enum SubsidyAdditionalDataDocumentTypeApiEnum {
    'application_file' = 'application_file',
    'contract_attachment' = 'contract_attachment',
}

export interface SubsidyAdditionalDataDocumentApiInterface {
    type: SubsidyAdditionalDataDocumentTypeApiEnum;
    name: string;
    description?: string;
}

export interface SubsidyDocumentApiInterface
    extends SubsidyAdditionalDataDocumentApiInterface {
    id: number;
    file: DocumentApiInterface;
}

export interface SubsidyLegalPersonListApiInterface {
    amount: number;
    subsidy: SubsidyLegalPersonSubsidyApiInterface;
    legal_person: LegalPersonBaseApiInterface;
    application: ApplicationEntityReferenceApiInterface | null;
    billing_application: ApplicationEntityReferenceApiInterface | null;
    actions?: SubsidyLegalPersonListActionsType;
}

export enum SubsidyApplicationStateEnum {
    none = 'none',
    work_in_progress = 'work_in_progress',
    fill_expenditure = 'fill_expenditure',
    waiting_for_approval = 'waiting_for_approval',
    waiting_for_contract_approval = 'waiting_for_contract_approval',
    waiting_for_invoice = 'waiting_for_invoice',
    waiting_for_invoice_approval = 'waiting_for_invoice_approval',
    waiting_for_payment = 'waiting_for_payment',
    waiting_for_additional_payment = 'waiting_for_additional_payment',
    waiting_for_return_payment = 'waiting_for_return_payment',
    subsidy_paid = 'subsidy_paid',
    declined = 'declined',
}

export enum SubsidyApplicationBillingStateEnum {
    none = 'none',
    waiting_for_billing_send = 'waiting_for_billing_send',
    waiting_for_approval = 'waiting_for_approval',
    approved = 'approved',
    waiting_for_fix_for_audit = 'waiting_for_fix_for_audit',
    waiting_for_audit_commission = 'waiting_for_audit_commission',
    checked_by_commission = 'checked_by_commission',
}

export type SubsidyLegalPersonListActionsType = Record<
    SubsidyLegalPersonListActionsApiEnum,
    ConfigModuleApiInterface
>;

export enum SubsidyLegalPersonListActionsApiEnum {
    'create_subsidy_application' = 'create_subsidy_application',
    'cancel_legal_person_subsidy' = 'cancel_legal_person_subsidy',
    'delete_legal_person_subsidy' = 'delete_legal_person_subsidy',
}

export interface SubsidyLegalPersonSubsidyApiInterface {
    id: number;
    name: string;
    group: string;
    competition_year: number;
}

export interface SubsidyLegalPersonSearchInterface {
    legal_person: LegalPersonApiInterface;
    already_in_subsidy: boolean;
}
