import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_LegalPerson_PageMenu_ApiEnum } from './LegalPersonDetailConfigApiInterface';
import { Config_Person_PageMenu_ApiEnum } from './PersonDetailConfigApiInterface';

export enum Config_AgentsInsuranceNew_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_AgentsInsuranceNew_Modules_ApiEnum {
    'overview' = 'overview',
    'indications' = 'indications',
}

export enum Config_AgentsInsuranceNew_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_AgentsInsuranceNew_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_AgentsInsuranceNew_ApiInterface
    extends ConfigDataInterface<
        Config_AgentsInsuranceNew_Modules_ApiEnum,
        Config_AgentsInsuranceNew_ActionsMenu_ApiEnum,
        Config_AgentsInsuranceNew_ActionsOther_ApiEnum,
        Config_LegalPerson_PageMenu_ApiEnum | Config_Person_PageMenu_ApiEnum,
        Config_AgentsInsuranceNew_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
