import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Match_PageMenu_ApiEnum } from './MatchDetailConfigApiInterface';

export enum Config_MatchRewards_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_MatchRewards_Modules_ApiEnum {
    'indications' = 'indications',
    'overview' = 'overview',
    'rewards' = 'rewards',
    'warning' = 'warning',
}

export enum Config_MatchRewards_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_MatchRewards_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_MatchRewards_ApiInterface
    extends ConfigDataInterface<
        Config_MatchRewards_Modules_ApiEnum,
        Config_MatchRewards_ActionsMenu_ApiEnum,
        Config_MatchRewards_ActionsOther_ApiEnum,
        Config_Match_PageMenu_ApiEnum,
        Config_MatchRewards_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
