import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_CompetitionDetail_ActionsMenu_ApiEnum {
    'club_signup' = 'club_signup',
    'manage_competition' = 'manage_competition',
    'delete_competition' = 'delete_competition',
    'cancel_competition' = 'cancel_competition',
    'create_global_change_request' = 'create_global_change_request',
}

export enum Config_CompetitionDetail_Modules_ApiEnum {
    'indications' = 'indications',
    'overview' = 'overview',
    'info' = 'info',
    'sign_up_to_competition' = 'sign_up_to_competition',
    'warning' = 'warning',
    'manage_competition' = 'manage_competition',
}

export enum Config_CompetitionDetail_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_Competition_PageMenu_ApiEnum {
    'competition-detail' = 'competition-detail',
    'competition-matches' = 'competition-matches',
    'competition-teams' = 'competition-teams',
    'competition-dates' = 'competition-dates',
    'competition-stats-for-players' = 'competition-stats-for-players',
    'competition-stats-for-teams' = 'competition-stats-for-teams',
    'competition-change-requests' = 'competition-change-requests',
}

export enum Config_CompetitionDetail_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_CompetitionDetail_ApiInterface
    extends ConfigDataInterface<
        Config_CompetitionDetail_Modules_ApiEnum,
        Config_CompetitionDetail_ActionsMenu_ApiEnum,
        Config_CompetitionDetail_ActionsOther_ApiEnum,
        Config_Competition_PageMenu_ApiEnum,
        Config_CompetitionDetail_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
