import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_AccountGrantsDocuments_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_AccountGrantsDocuments_Modules_ApiEnum {
    'results' = 'results',
}

export enum Config_AccountGrantsDocuments_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_AccountGrantsDocuments_PageMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_AccountGrantsDocuments_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_AccountGrantsDocuments_ApiInterface
    extends ConfigDataInterface<
        Config_AccountGrantsDocuments_Modules_ApiEnum,
        Config_AccountGrantsDocuments_ActionsMenu_ApiEnum,
        Config_AccountGrantsDocuments_ActionsOther_ApiEnum,
        Config_AccountGrantsDocuments_PageMenu_ApiEnum,
        Config_AccountGrantsDocuments_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
