import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Club_PageMenu_ApiEnum } from './ClubDetailConfigApiInterface';

export enum Config_ClubCompetition_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_ClubCompetition_Modules_ApiEnum {
    'indications' = 'indications',
    'overview' = 'overview',
    'players' = 'players',
}

export enum Config_ClubCompetition_ActionsOther_ApiEnum {
    'upload_photo' = 'upload_photo',
}

export enum Config_ClubCompetition_PageSubMenu_ApiEnum {
    'club-competition' = 'club-competition',
    'club-competition-team-list-players-autumn' = 'club-competition-team-list-players-autumn',
    'club-competition-team-list-players-spring' = 'club-competition-team-list-players-spring',
}

export interface Config_ClubCompetition_ApiInterface
    extends ConfigDataInterface<
        Config_ClubCompetition_Modules_ApiEnum,
        Config_ClubCompetition_ActionsMenu_ApiEnum,
        Config_ClubCompetition_ActionsOther_ApiEnum,
        Config_Club_PageMenu_ApiEnum,
        Config_ClubCompetition_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
