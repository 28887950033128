import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_RegulationDetail_ActionsMenu_ApiEnum {
    'regulation_delete' = 'regulation_delete',
    'regulation_edit' = 'regulation_edit',
    'regulation_archive' = 'regulation_archive',
}

export enum Config_RegulationDetail_Modules_ApiEnum {
    'results' = 'results',
}

export enum Config_RegulationDetail_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_RegulationDetail_PageMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_RegulationDetail_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_RegulationDetail_ApiInterface
    extends ConfigDataInterface<
        Config_RegulationDetail_Modules_ApiEnum,
        Config_RegulationDetail_ActionsMenu_ApiEnum,
        Config_RegulationDetail_ActionsOther_ApiEnum,
        Config_RegulationDetail_PageMenu_ApiEnum,
        Config_RegulationDetail_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
