import {
    ClubPassportApiInterface,
    ClubReferenceApiInterface,
    DisciplineEnum,
    LevelTypeEnum,
    PersonEntityReferenceApiInterface,
    RegistrationCauseEnum,
    RegistrationTypeEnum,
    TransferDirectionEnum,
    TransferTypeEnum,
} from '../ApiInterface';
import { ClubPlayerTransferDeclarationTypeEnum } from './ClubInterface';
import { PersonBasicDataApiInterface } from './PersonInterface';

export interface PlayerSportRegistration {
    id: number;
    club: ClubPassportApiInterface;
    owner_club_before: ClubReferenceApiInterface;
    active: boolean;
    discipline: DisciplineEnum;
    registration_cause: RegistrationCauseEnum;
    registration_type: RegistrationTypeEnum;
    transfer_type: TransferTypeEnum;
    transfer_direction: TransferDirectionEnum;
    level_type: LevelTypeEnum;
    date_from: string;
    date_to: string;
    is_temporary: boolean | null;
    person: PersonEntityReferenceApiInterface;
}

export interface PlayerRegistrationsDeclaration {
    id: number;
    type: ClubPlayerTransferDeclarationTypeEnum;
    type_label: string;
    contract_end_date: string;
    state: PlayerDeclarationStateEnum;
    returned_reason: string | null;
    payments: PlayerRegistrationsDeclarationPaymentInterface[];
    created_at: string;
    created_by_user: { id: number; person: PersonBasicDataApiInterface };
    updated_at: string;
    updated_by_user: { id: number; person: PersonBasicDataApiInterface };
}

export enum PlayerDeclarationStateEnum {
    draft = 'draft',
    review = 'review',
    returned = 'returned',
    approved = 'approved',
    declaration_approve = 'declaration_approve',
}

export interface PlayerRegistrationsDeclarationPaymentInterface {
    amount: number;
    created_at: string;
    created_by_user: { id: number; person: PersonBasicDataApiInterface };
    currency: string;
    due_date: string;
    id: number;
    is_paid: boolean;
    note: string;
    type: PlayerDeclarationPaymentTypeEnum;
    updated_at: string;
    updated_by_user: { id: number; person: PersonBasicDataApiInterface };
}

export enum PlayerDeclarationPaymentTypeEnum {
    'value' = 'value',
    'conditional_value' = 'conditional_value',
    'sell_on_fee' = 'sell_on_fee',
    'sell_on_fee_calculated' = 'sell_on_fee_calculated',
}

export interface PlayerUpdateDeclarationPaymentInterface {
    payments: PlayerUpdateDeclarationPaymentsInterface[];
    contract_end_date: string;
}

export interface PlayerUpdateDeclarationPaymentsInterface {
    id?: number;
    type: PlayerDeclarationPaymentTypeEnum;
    amount: number;
    currency: string;
    note: string;
    due_date: string;
}

export interface PlayerUpdateDeclarationPaymentStateInterface {
    state: PlayerDeclarationStateEnum;
    reason?: string;
}

export interface PlayerRegistrationTerminateApiInterface {
    discipline: DisciplineEnum;
}
