import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Club_PageMenu_ApiEnum } from './ClubDetailConfigApiInterface';

export enum Config_ClubBulkMemberships_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_ClubBulkMemberships_Modules_ApiEnum {
    'overview' = 'overview',
    'results' = 'results',
}

export enum Config_ClubBulkMemberships_ActionsOther_ApiEnum {
    'upload_photo' = 'upload_photo',
}

export enum Config_ClubBulkMemberships_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_ClubBulkMemberships_ApiInterface
    extends ConfigDataInterface<
        Config_ClubBulkMemberships_Modules_ApiEnum,
        Config_ClubBulkMemberships_ActionsMenu_ApiEnum,
        Config_ClubBulkMemberships_ActionsOther_ApiEnum,
        Config_Club_PageMenu_ApiEnum,
        Config_ClubBulkMemberships_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
