import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_ApplicationDetail_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_ApplicationDetail_Modules_ApiEnum {
    'overview' = 'overview',
    'read' = 'read',
    'write' = 'write',
    'state_history' = 'state_history',
}

export enum Config_ApplicationDetail_ActionsOther_ApiEnum {
    'approve' = 'approve',
    'decline' = 'decline',
    'save' = 'save',
    'return_to_update' = 'return_to_update',
    'export_tic' = 'export_tic',
}

export enum Config_ApplicationDetail_PageMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_ApplicationDetail_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_ApplicationDetail_ApiInterface
    extends ConfigDataInterface<
        Config_ApplicationDetail_Modules_ApiEnum,
        Config_ApplicationDetail_ActionsMenu_ApiEnum,
        Config_ApplicationDetail_ActionsOther_ApiEnum,
        Config_ApplicationDetail_PageMenu_ApiEnum,
        Config_ApplicationDetail_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
