import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_OrganisationUnitDetail_PageMenu_ApiEnum } from './OrganisationUnitDetailConfigApiInterface';

export enum Config_OrganisationUnitRole_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_OrganisationUnitRole_Modules_ApiEnum {
    'overview' = 'overview',
    'results' = 'results',
}

export enum Config_OrganisationUnitRole_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_OrganisationUnitRole_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_OrganisationUnitRole_ApiInterface
    extends ConfigDataInterface<
        Config_OrganisationUnitRole_Modules_ApiEnum,
        Config_OrganisationUnitRole_ActionsMenu_ApiEnum,
        Config_OrganisationUnitRole_ActionsOther_ApiEnum,
        Config_OrganisationUnitDetail_PageMenu_ApiEnum,
        Config_OrganisationUnitRole_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
