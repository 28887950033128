import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Competition_PageMenu_ApiEnum } from './CompetitionDetailConfigApiInterface';

export enum Config_CompetitionEditTeam_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_CompetitionEditTeam_Modules_ApiEnum {
    'form' = 'form',
}

export enum Config_CompetitionEditTeam_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_CompetitionEditTeam_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_CompetitionEditTeam_ApiInterface
    extends ConfigDataInterface<
        Config_CompetitionEditTeam_Modules_ApiEnum,
        Config_CompetitionEditTeam_ActionsMenu_ApiEnum,
        Config_CompetitionEditTeam_ActionsOther_ApiEnum,
        Config_Competition_PageMenu_ApiEnum,
        Config_CompetitionEditTeam_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
