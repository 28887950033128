import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Person_PageMenu_ApiEnum } from './PersonDetailConfigApiInterface';

export enum Config_PlayerContracts_ActionsMenu_ApiEnum {
    'archive' = 'archive',
    'create_contract' = 'create_contract',
}

export enum Config_PlayerContracts_Modules_ApiEnum {
    'indications' = 'indications',
    'overview' = 'overview',
    'contracts' = 'contracts',
}

export enum Config_PlayerContracts_ActionsOther_ApiEnum {
    'upload_photo' = 'upload_photo',
    'export_passport' = 'export_passport',
}

export enum Config_PlayerContracts_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_PlayerContracts_ApiInterface
    extends ConfigDataInterface<
        Config_PlayerContracts_Modules_ApiEnum,
        Config_PlayerContracts_ActionsMenu_ApiEnum,
        Config_PlayerContracts_ActionsOther_ApiEnum,
        Config_Person_PageMenu_ApiEnum,
        Config_PlayerContracts_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
