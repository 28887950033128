import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_AccountRewardsInvoices_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_AccountRewardsInvoices_Modules_ApiEnum {
    'results' = 'results',
}

export enum Config_AccountRewardsInvoices_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_AccountRewardsInvoices_PageMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_AccountRewardsInvoices_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_AccountRewardsInvoices_ApiInterface
    extends ConfigDataInterface<
        Config_AccountRewardsInvoices_Modules_ApiEnum,
        Config_AccountRewardsInvoices_ActionsMenu_ApiEnum,
        Config_AccountRewardsInvoices_ActionsOther_ApiEnum,
        Config_AccountRewardsInvoices_PageMenu_ApiEnum,
        Config_AccountRewardsInvoices_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
