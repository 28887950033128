import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Competition_PageMenu_ApiEnum } from './CompetitionDetailConfigApiInterface';

export enum Config_CompetitionDates_ActionsMenu_ApiEnum {
    'generate_competition_dates' = 'generate_competition_dates',
    'generate_competition_matches' = 'generate_competition_matches',
    'copy_competition_dates' = 'copy_competition_dates',
}

export enum Config_CompetitionDates_Modules_ApiEnum {
    'indications' = 'indications',
    'overview' = 'overview',
    'dates_spring' = 'dates_spring',
    'dates_autumn' = 'dates_autumn',
    'generate_competition_dates' = 'generate_competition_dates',
    'generate_competition_matches' = 'generate_competition_matches',
    'warning' = 'warning',
}

export enum Config_CompetitionDates_ActionsOther_ApiEnum {
    'create_competition_dates_autumn' = 'create_competition_dates_autumn',
    'create_competition_dates_spring' = 'create_competition_dates_spring',
    'generate_competition_date_berger_number' = 'generate_competition_date_berger_number',
}

export enum Config_CompetitionDates_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_CompetitionDates_ApiInterface
    extends ConfigDataInterface<
        Config_CompetitionDates_Modules_ApiEnum,
        Config_CompetitionDates_ActionsMenu_ApiEnum,
        Config_CompetitionDates_ActionsOther_ApiEnum,
        Config_Competition_PageMenu_ApiEnum,
        Config_CompetitionDates_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
