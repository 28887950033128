import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Person_PageMenu_ApiEnum } from './PersonDetailConfigApiInterface';

export enum Config_InternalUserDetail_ActionsMenu_ApiEnum {
    'archive' = 'archive',
    'send_activation_email' = 'send_activation_email',
    'resend_setup_user' = 'resend_setup_user',
}

export enum Config_InternalUserDetail_Modules_ApiEnum {
    'indications' = 'indications',
    'overview' = 'overview',
    'user-access' = 'user-access',
    'roles' = 'roles',
}

export enum Config_InternalUserDetail_ActionsOther_ApiEnum {
    'upload_photo' = 'upload_photo',
    'create_user_account' = 'create_user_account',
}

export enum Config_InternalUserDetail_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_InternalUserDetail_ApiInterface
    extends ConfigDataInterface<
        Config_InternalUserDetail_Modules_ApiEnum,
        Config_InternalUserDetail_ActionsMenu_ApiEnum,
        Config_InternalUserDetail_ActionsOther_ApiEnum,
        Config_Person_PageMenu_ApiEnum,
        Config_InternalUserDetail_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
