import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Match_PageMenu_ApiEnum } from './MatchDetailConfigApiInterface';

export enum Config_MatchDelegateReport_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_MatchDelegateReport_Modules_ApiEnum {
    'indications' = 'indications',
    'overview' = 'overview',
    'report' = 'report',
    'rankings' = 'rankings',
    'warning' = 'warning',
}

export enum Config_MatchDelegateReport_ActionsOther_ApiEnum {
    'finish_match_delegate_report' = 'finish_match_delegate_report',
}

export enum Config_MatchDelegateReport_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_MatchDelegateReport_ApiInterface
    extends ConfigDataInterface<
        Config_MatchDelegateReport_Modules_ApiEnum,
        Config_MatchDelegateReport_ActionsMenu_ApiEnum,
        Config_MatchDelegateReport_ActionsOther_ApiEnum,
        Config_Match_PageMenu_ApiEnum,
        Config_MatchDelegateReport_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
