import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_OrganisationUnitDetail_ActionsMenu_ApiEnum {
    'change_legal_person' = 'change_legal_person',
}

export enum Config_OrganisationUnitDetail_Modules_ApiEnum {
    'overview' = 'overview',
    'contacts' = 'contacts',
    'bank' = 'bank',
    'shipping' = 'shipping',
}

export enum Config_OrganisationUnitDetail_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_OrganisationUnitDetail_PageMenu_ApiEnum {
    'organisation-unit-detail' = 'organisation-unit-detail',
    'organisation-unit-account-transactions' = 'organisation-unit-account-transactions',
    'organisation-unit-account-subsidies' = 'organisation-unit-account-subsidies',
    'organisation-unit-account-grants' = 'organisation-unit-account-grants',
    'organisation-unit-persons' = 'organisation-unit-persons',
    'organisation-unit-nominations-referee-commission' = 'organisation-unit-nominations-referee-commission',
    'organisation-unit-nominations-delegate-commission' = 'organisation-unit-nominations-delegate-commission',
    'organisation-unit-applications' = 'organisation-unit-applications',
}

export enum Config_OrganisationUnitDetail_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_OrganisationUnitDetail_ApiInterface
    extends ConfigDataInterface<
        Config_OrganisationUnitDetail_Modules_ApiEnum,
        Config_OrganisationUnitDetail_ActionsMenu_ApiEnum,
        Config_OrganisationUnitDetail_ActionsOther_ApiEnum,
        Config_OrganisationUnitDetail_PageMenu_ApiEnum,
        Config_OrganisationUnitDetail_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
