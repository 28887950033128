import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_ClubUnions_ActionsMenu_ApiEnum {
    'create_club_union' = 'create_club_union',
}

export enum Config_ClubUnions_Modules_ApiEnum {
    'results' = 'results',
}

export enum Config_ClubUnions_ActionsOther_ApiEnum {
    'create' = 'create',
}

export enum Config_ClubUnions_PageMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_ClubUnions_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_ClubUnions_ApiInterface
    extends ConfigDataInterface<
        Config_ClubUnions_Modules_ApiEnum,
        Config_ClubUnions_ActionsMenu_ApiEnum,
        Config_ClubUnions_ActionsOther_ApiEnum,
        Config_ClubUnions_PageMenu_ApiEnum,
        Config_ClubUnions_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
