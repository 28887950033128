import { ApplicationEntityReferenceApiInterface } from '../ApiInterface';
import { ConfigModuleApiInterface } from '../config/ConfigApiInterface';
import { AccountDocumentListApiInterface } from './AccountDocumentInterface';

export interface AccountSubsidyListApiInterface {
    document: AccountDocumentListApiInterface;
    subsidy_application: AccountSubsidyApplicationApiInterface | null;
    actions?: Record<
        AccountSubsidyListActionsApiEnum,
        ConfigModuleApiInterface
    >;
}

export interface AccountSubsidyApplicationApiInterface {
    subsidy_id: number;
    application: ApplicationEntityReferenceApiInterface;
}

export enum AccountSubsidyListActionsApiEnum {
    'open_subsidy_application' = 'open_subsidy_application',
}
