import {
    DisciplineEnum,
    EntityReferenceApiEnum,
    EntityReferenceWithTypeApiInterface,
} from '../ApiInterface';
import { ConfigModuleApiInterface } from '../config/ConfigApiInterface';
import { DocumentSourceTypeEnum } from './AccountDocumentInterface';

export interface CreateTransactionListApiInterface {
    discipline: DisciplineEnum;
    for_type: EntityReferenceApiEnum;
    for_id: number;
    amount: number;
    template_id: number;
    count: number;
    append_text: string;
}

export interface TransactionsTemplatesApiInterface {
    id: number;
    code: TransactionsTemplatesCodeApiEnum;
    discipline: DisciplineEnum;
    area_code: string;
    type: TransactionTypeApiEnum;
    is_system_only: boolean;
    name: string;
    amount_from: number;
    amount_to: number;
    created_at: string;
    updated_at: string;
    archived_at: string;
    pass_to: TransactionsTemplatesPassToApiInterface[];
}

export type TransactionsTemplatesPassToApiType =
    | 'club'
    | 'organisation_unit'
    | 'association_futsal'
    | 'association_football';

export interface TransactionsTemplatesPassToApiInterface {
    type: TransactionsTemplatesPassToApiType;
    percentage: number;
}

export interface TransactionsTemplatesListApiInterface {
    id: number;
    code: TransactionsTemplatesCodeApiEnum;
    discipline: DisciplineEnum;
    area_code: string;
    type: TransactionTypeApiEnum;
    is_system_only: boolean;
    has_pass_to: boolean;
    name: string;
    amount_from: number;
    amount_to: number;
    created_at: string;
    updated_at: string;
    archived_at: string;
}

export enum TransactionsTemplatesCodeApiEnum {
    match_reward = 'match_reward',
    match_transportation = 'match_transportation',
    first_registration = 'first_registration',
    transfer_professional = 'transfer_professional',
    hosting = 'hosting',
    transfer = 'transfer',
    transfer_foreign_first_registration = 'transfer_foreign_first_registration',
    transfer_foreign_update = 'transfer_foreign_update',
    dp_1 = 'dp_1',
    dp_appeal = 'dp_appeal',
    dp_justification = 'dp_justification',
    pp = 'pp',
    pp_appeal = 'pp_appeal',
    pp_fine_1 = 'pp_fine_1',
    pp_fine_appeal = 'pp_fine_appeal',
    pc_1 = 'pc_1',
    pc_appeal = 'pc_appeal',
    pc_reason = 'pc_reason',
    pm = 'pm',
}

export interface TransactionBaseApiInterface {
    id: number;
    owner?: EntityReferenceWithTypeApiInterface;
    total_amount_with_vat: number;
    is_cancelled: boolean;
    document?: TransactionDocumentApiInterface;
    created_at: string;
}

export interface TransactionApiInterface extends TransactionBaseApiInterface {
    amount: number;
    count: number;
    discipline: DisciplineEnum;
    related_to: EntityReferenceWithTypeApiInterface[];
    return_transaction_id: number;
    originated_from_transaction_id: number;
    text: string;
    total_amount: number;
    type: TransactionTypeApiEnum;
    vat: number;
    vat_percentage: number;
    updated_at: string;
    originated_from_transaction: TransactionBaseApiInterface;
    return_transaction: TransactionBaseApiInterface;
    pass_transactions: TransactionBaseApiInterface[];
}

export interface TransactionDocumentApiInterface {
    id: number;
    number: string;
    paid_state: 0 | 1 | 2 | 3;
    variable_symbol: string;
    source_type: DocumentSourceTypeEnum;
    owner_id: number;
    owner_type: EntityReferenceApiEnum;
}

export interface TransactionListApiInterface {
    id: number;
    created_at: string;
    updated_at: string;
    type: TransactionTypeApiEnum;
    text: string;
    count: number;
    amount: number;
    total_amount: number;
    total_amount_with_vat: number;
    document?: TransactionDocumentApiInterface;
    related_to: EntityReferenceWithTypeApiInterface[];
    owner?: EntityReferenceWithTypeApiInterface;
    is_cancelled: boolean;
    return_transaction_id: number;
    vat: number;
    vat_percentage: number;
    actions?: TransactionListActionsApiInterface;
}

export interface TransactionListActionsApiInterface {
    cancel_transaction: ConfigModuleApiInterface;
    transaction_detail: ConfigModuleApiInterface;
    transaction_contradiction: ConfigModuleApiInterface;
}

export enum TransactionTypeApiEnum {
    reward = 'reward',
    transportation = 'transportation',
    fee = 'fee',
    fine = 'fine',
}
