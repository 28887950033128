import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_SubsidiesLegalPerson_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_SubsidiesLegalPerson_Modules_ApiEnum {
    'results' = 'results',
}

export enum Config_SubsidiesLegalPerson_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_SubsidiesLegalPerson_PageMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_SubsidiesLegalPerson_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_SubsidiesLegalPerson_ApiInterface
    extends ConfigDataInterface<
        Config_SubsidiesLegalPerson_Modules_ApiEnum,
        Config_SubsidiesLegalPerson_ActionsMenu_ApiEnum,
        Config_SubsidiesLegalPerson_ActionsOther_ApiEnum,
        Config_SubsidiesLegalPerson_PageMenu_ApiEnum,
        Config_SubsidiesLegalPerson_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
