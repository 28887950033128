import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Match_PageMenu_ApiEnum } from './MatchDetailConfigApiInterface';

export enum Config_MatchSum_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_MatchSum_Modules_ApiEnum {
    'sum' = 'sum',
}

export enum Config_MatchSum_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_MatchSum_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_MatchSum_ApiInterface
    extends ConfigDataInterface<
        Config_MatchSum_Modules_ApiEnum,
        Config_MatchSum_ActionsMenu_ApiEnum,
        Config_MatchSum_ActionsOther_ApiEnum,
        Config_Match_PageMenu_ApiEnum,
        Config_MatchSum_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
