import { ConfigDataInterface } from './ConfigApiInterface';
import { Config_Person_PageMenu_ApiEnum } from './PersonDetailConfigApiInterface';

export enum Config_PersonAccountIssuedDocument_ActionsMenu_ApiEnum {
    'print_document' = 'print_document',
    'mark_document_as_final' = 'mark_document_as_final',
}

export enum Config_PersonAccountIssuedDocument_Modules_ApiEnum {
    'indications' = 'indications',
    'overview' = 'overview',
    'results' = 'results',
}

export enum Config_PersonAccountIssuedDocument_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_PersonAccountIssuedDocument_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_PersonAccountIssuedDocument_ApiInterface
    extends ConfigDataInterface<
        Config_PersonAccountIssuedDocument_Modules_ApiEnum,
        Config_PersonAccountIssuedDocument_ActionsMenu_ApiEnum,
        Config_PersonAccountIssuedDocument_ActionsOther_ApiEnum,
        Config_Person_PageMenu_ApiEnum,
        Config_PersonAccountIssuedDocument_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
