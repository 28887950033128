import {
    BillingFieldsApiInterface,
    DisciplineEnum,
    EntityReferenceWithTypeApiInterface,
} from '../ApiInterface';
import { ConfigModuleApiInterface } from '../config/ConfigApiInterface';

export interface AccountDocumentApiInterface {
    id: number;
    paid_state: DocumentPaidStateByNumberApiEnum;
    number?: number;
    variable_symbol?: string;
    discipline?: DisciplineEnum;
    state: DocumentStateByNumberEnum;
    type: DocumentTypeEnum;
    source_type: DocumentSourceTypeEnum;
    amount: number;
    amount_with_vat: number;
    note?: string;
    issued_date: string;
    taxable_date: string;
    due_date?: string;
    paid_at?: string;
    created_at: string;
    updated_at: string;
    owner: EntityReferenceWithTypeApiInterface;
    billing_fields: BillingFieldsApiInterface;
    supplier_type: AccountDocumentSupplierTypeApiEnum;
}

export enum AccountDocumentSupplierTypeApiEnum {
    owner = 'owner',
    association = 'association',
}

export interface AccountDocumentListApiInterface {
    id: number;
    number: number;
    paid_state: DocumentPaidStateByNumberApiEnum;
    discipline?: DisciplineEnum;
    state: DocumentStateByNumberEnum;
    type: DocumentTypeEnum;
    source_type: DocumentSourceTypeEnum;
    variable_symbol?: string;
    owner: EntityReferenceWithTypeApiInterface;
    amount: number;
    amount_with_vat: number;
    note?: string;
    issued_date: string;
    due_date?: string;
    paid_at?: string;
    created_at: string;
    updated_at: string;
    actions?: AccountDocumentActionsApiInterface;
}

export interface AccountDocumentActionsApiInterface {
    mark_document_as_final: ConfigModuleApiInterface;
}

export enum DocumentPaidStateByNumberApiEnum {
    not_paid = 0,
    under_paid = 1,
    paid = 2,
    over_paid = 3,
}

export enum DocumentStateByNumberEnum {
    draft = 0,
    final = 1,
}

export enum DocumentTypeEnum {
    'credit_note' = 'credit_note',
    'invoice' = 'invoice',
    'grant' = 'grant',
    'subsidy' = 'subsidy',
}
export enum DocumentSourceTypeEnum {
    'fine' = 'fine',
    'collection' = 'collection',
    'collection_rewards' = 'collection_rewards',
    'collection_grant' = 'collection_grant',
    'subsidy' = 'subsidy',
}
