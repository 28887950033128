import { ConfigDataInterface } from './ConfigApiInterface';

export enum Config_DecisionCreate_ActionsMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_DecisionCreate_Modules_ApiEnum {
    'form' = 'form',
}

export enum Config_DecisionCreate_ActionsOther_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_DecisionCreate_PageMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export enum Config_DecisionCreate_PageSubMenu_ApiEnum {
    'NOTHING' = 'NOTHING',
}

export interface Config_DecisionCreate_ApiInterface
    extends ConfigDataInterface<
        Config_DecisionCreate_Modules_ApiEnum,
        Config_DecisionCreate_ActionsMenu_ApiEnum,
        Config_DecisionCreate_ActionsOther_ApiEnum,
        Config_DecisionCreate_PageMenu_ApiEnum,
        Config_DecisionCreate_PageSubMenu_ApiEnum
    > {
    _?: boolean;
}
